import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { SocketioService } from "../../services/socketio/socketio.service";
import { urls } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { ColoursService } from "../../services/colours/colours.service";
import { SessionStatusService } from "../../services/session-status/session-status.service";
import axios from "axios";

@Component({
  selector: "app-load-session-page",
  templateUrl: "./load-session-page.component.html",
  styleUrls: ["./load-session-page.component.scss"],
})
export class LoadSessionPageComponent implements OnInit {
  constructor(
    private router: Router,
    private sio: SocketioService,
    private auth: AuthService,
    public colours: ColoursService,
    public status: SessionStatusService
  ) {}

  flagColour = {
    GREEN: "green",
    FULL_YELLOW: "yellow",
    SAFETY_CAR: "orange",
    RED: "red",
  };

  events = {};

  selectedSessionArr: any;
  sessions: Array<object>;
  laps: Array<object>;
  selectedLapsArr: any;
  standings: Array<object>;
  newSessionName: string;
  waitingForCallback: boolean = false;

  ngOnInit(): void {
    axios
      .get(urls["server"] + "api/racetool/get-sessions", this.auth.apiConfig)
      .then((resp) => {
        this.sessions = resp.data;
      })
      .catch((e) => {});
    this.sioEventListeners();
    this.sioJoinRooms();
  }

  ngOnDestroy(): void {
    this.sioRemoveEventListeners();
    this.sioLeaveRooms();
  }

  sioEventListeners() {
    this.sio.server.on("finishedSettingName", () => {
      if (this.waitingForCallback) {
        this.sio.writeAPI("START_LOAD", {});
        this.waitingForCallback = false;
      }
    });
  }

  sioJoinRooms() {
    this.sio.joinRoom(this.sio.server, "setNameCallback");
  }

  sioRemoveEventListeners() {
    this.sio.server.off("finishedSettingName");
  }

  sioLeaveRooms() {
    this.sio.leaveRoom(this.sio.server, "setNameCallback");
  }

  home() {
    this.router.navigateByUrl("/race-manager");
  }

  homeNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/race-manager", "_blank");
  }

  loadSession(session: object) {
    let id = session["_id"];
    this.getLaps(id);
  }

  getLaps(id: string): void {
    axios
      .get(urls["server"] + "api/racetool/get-laps/" + id, this.auth.apiConfig)
      .then((resp) => {
        this.laps = resp.data;
      })
      .catch((err) => {
        this.laps = [];
        console.log(err.response.status);
      });
  }

  loadLap(lap: object) {
    let standingsArr = [];
    for (let [driverNum, driverObj] of Object.entries(lap["cars"])) {
      if (driverNum == "CarManager" || driverNum == "SC") continue;
      let obj = { driverNum: driverNum };
      Object.assign(obj, driverObj);
      standingsArr.push(obj);
    }
    standingsArr.sort(
      (a, b) => b.currentLap - a.currentLap || b.position - a.position
    );
    this.standings = standingsArr;
    this.newSessionName = `${this.selectedSessionArr[0]["name"]} Replay`;
  }

  loadSaveState() {
    let id = this.selectedLapsArr[0]["_id"];
    this.sio.writeAPI("LOAD_BASIC", { id: id });
  }

  startSaveState() {
    this.sio.writeAPI("SET_NAME", { name: this.newSessionName });
    this.waitingForCallback = true;
  }
}
