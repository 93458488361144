/* eslint-disable sonarjs/no-duplicate-string */
import { Component, OnInit, Injectable } from "@angular/core";
import { RaceControlService } from "../../../services/race-control/race-control.service";

type AlertData = {
  backgroundColor: string;
  foregroundColor: string;
  text: string;
  dt: Date;
  duration: number;
};

const INTERVENTION_FLAG_MESSAGES = [
  "SAFETY CAR DEPLOYED",
  "FULL COURSE YELLOW",
];

@Component({
  selector: "app-alerts-popup",
  templateUrl: "./alerts-popup.component.html",
  styleUrls: ["./alerts-popup.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class AlertsPopupComponent implements OnInit {
  constructor(private rc: RaceControlService) {}

  events = {};
  isActive = false;
  timeout: NodeJS.Timeout;

  ngOnInit(): void {
    this.subEvents();
  }

  ngOnDestroy(): void {
    this.unsubEvents();
  }

  subEvents(): void {
    this.events["raceControl"] = this.rc.raceControlEvent.subscribe(
      (data: AlertData) => {
        this.newAlert(data);
      }
    );
    this.events["raceControlFlag"] = this.rc.raceControlFlagEvent.subscribe(
      (data: AlertData) => {
        this.newFlag(data);
      }
    );
  }

  unsubEvents(): void {
    if (this.events["raceControl"] != undefined)
      this.events["raceControl"].unsubscribe();
    if (this.events["raceControlFlag"] != undefined)
      this.events["raceControlFlag"].unsubscribe();
  }

  newAlert(data: AlertData): void {
    if (!INTERVENTION_FLAG_MESSAGES.includes(data.text)) {
      this.displayMsg(data);
    }
    if (data.text === "GREEN FLAG") {
      this.removeFlag();
    }
  }

  displayMsg(data: AlertData): void {
    clearTimeout(this.timeout);
    if (!this.isActive) {
      document.getElementById("message_div").style.marginTop = "0%";
      document.getElementById("message_div").style.display = "";
      this.isActive = true;
    }
    document.getElementById("message_div").style.backgroundColor =
      data.backgroundColor;
    document.getElementById("message_div").style.color = data.foregroundColor;
    const dateString = data.dt.toLocaleTimeString("en-gb");
    document.getElementById(
      "message_div"
    ).innerHTML = `${dateString} -- ${data.text}`;
    document.getElementById("message_div").classList.remove("slide-down");
    document.getElementById("message_div").classList.add("slide-up");
    this.timeout = setTimeout(() => {
      document.getElementById("message_div").classList.remove("slide-up");
      document.getElementById("message_div").classList.add("slide-down");
      this.isActive = false;
    }, data.duration * 1000);
  }

  removeMsg(): void {
    clearTimeout(this.timeout);
    document.getElementById("message_div").classList.remove("slide-up");
    document.getElementById("message_div").classList.add("slide-down");
    this.isActive = false;
  }

  newFlag(data: AlertData): void {
    this.displayFlag(data);
    this.removeMsg();
  }

  displayFlag(data: AlertData): void {
    if (!this.isActive) {
      document.getElementById("flag_div").style.marginTop = "0%";
      document.getElementById("flag_div").style.display = "";
      this.isActive = true;
    }
    document.getElementById("flag_div").style.backgroundColor =
      data.backgroundColor;
    document.getElementById("flag_div").style.color = data.foregroundColor;
    const dateString = data.dt.toLocaleTimeString("en-gb");
    document.getElementById(
      "flag_div"
    ).innerHTML = `${dateString} -- ${data.text}`;
    document.getElementById("flag_div").classList.remove("slide-down");
    document.getElementById("flag_div").classList.add("slide-up");
  }

  removeFlag(): void {
    document.getElementById("flag_div").classList.remove("slide-up");
    document.getElementById("flag_div").classList.add("slide-down");
    this.isActive = false;
  }
}
