<app-main-nav>
  <div
    id="main"
    class="fadeIn"
    style="
      background-image: url(./assets/images/race_control.png);
      background-position: 0% 35%;
      background-size: cover;
    "
  >
    <button
      mat-raised-button
      id="homeButton"
      (click)="home()"
      (auxclick)="homeNewTab($event)"
    >
      <mat-icon>home</mat-icon>
    </button>
    <div id="centerBox">
      <div id="titleRow" class="jaguarFont">Race Manager</div>
      <div id="energiesPanel">
        <div id="energiesTable">
          <app-energies-table></app-energies-table>
        </div>
        <div id="energiesToggle" class="jaguarFont">
          Show Live Energies:
          <mat-slide-toggle
            [(ngModel)]="showLiveEnergies"
            (change)="setLiveEnergies()"
            style="color: red"
          ></mat-slide-toggle>
        </div>
      </div>
      <div id="resetPanel" class="jaguarFont">
        Race Tool Config
        <div id="resetFieldWrapper">
          <mat-form-field id="resetField" appearance="none">
            <mat-label>Select Config...</mat-label>
            <mat-select
              [(value)]="configs.chosenConfig"
              id="resetSelect"
              style="width: 24rem !important"
            >
              <mat-option
                *ngFor="let cfg of configs.availableConfigs"
                [value]="cfg"
              >
                {{ cfg }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button (click)="reset()">Reset</button>
        </div>
        <div id="saveSessionWrapper">
          <div id="sessionNameTitle">Session Name:</div>
          <div id="sessionNameFormWrapper">
            <form class="example-form">
              <mat-form-field appearance="none" id="sessionNameFormField">
                <mat-label id="sessionNameLabel">&nbsp;Session Name</mat-label>
                <input
                  matInput
                  id="sessionNameInput"
                  [(ngModel)]="sessionName"
                  name="sessionName"
                />
              </mat-form-field>
            </form>
          </div>
          <mat-checkbox
            [(ngModel)]="saveSession"
            (change)="setSaveSession()"
            id="saveSessionCheckbox"
          >
            Save session?
          </mat-checkbox>
        </div>
        <div id="menuButtons">
          <button
            mat-raised-button
            id="importGeneratorButton"
            class="jaguarFont"
            (click)="goToImportGenerator()"
            (auxclick)="goToImportGeneratorNewTab($event)"
          >
            Make Import
          </button>
          <button
            mat-raised-button
            id="loadSessionbutton"
            class="jaguarFont"
            (click)="goToLoadSessionMenu()"
            (auxclick)="goToLoadSessionMenuNewTab($event)"
          >
            Load Session
          </button>
        </div>
      </div>
      <div id="flagPanel" class="jaguarFont">
        <div id="flagTitle">Flags</div>

        <div id="flagContainer">
          <button
            mat-raised-button
            id="safetyCarInButton"
            class="flagButton jaguarFont"
            (click)="flag('GREEN')"
            [disabled]="safetyCarComingIn"
            [style.display]="
              status.status['currentFlag'] == 'SAFETY_CAR' ? 'block' : 'none'
            "
          >
            SC IN
          </button>
          <button
            mat-raised-button
            id="greenFlagButton"
            class="flagButton jaguarFont"
            (click)="flag('GREEN')"
            [disabled]="
              safetyCarComingIn || status.status['currentFlag'] == 'GREEN'
            "
            [style.display]="
              status.status['currentFlag'] == 'SAFETY_CAR' ? 'none' : 'block'
            "
          >
            GREEN FLAG
          </button>
          <button
            mat-raised-button
            id="yellowFlagButton"
            class="flagButton jaguarFont"
            (click)="flag('FULL_YELLOW')"
            [disabled]="status.status['currentFlag'] == 'FULL_YELLOW'"
            [style.display]="
              status.status['currentFlag'] == 'SAFETY_CAR' ? 'none' : 'block'
            "
          >
            FCY FLAG
          </button>
          <button
            mat-raised-button
            id="safetyCarButton"
            class="flagButton jaguarFont"
            (click)="flag('SAFETY_CAR')"
            [style.display]="
              status.status['currentFlag'] == 'SAFETY_CAR' ? 'none' : 'block'
            "
          >
            SAFETY CAR
          </button>
        </div>
        <div
          id="safetyCarSliderContainer"
          [style.visibility]="
            status.status['currentFlag'] == 'SAFETY_CAR' ? 'visible' : 'hidden'
          "
        >
          Safety Car Pace
          <div id="safetyCarSliderRow">
            -
            <mat-slider
              step="1"
              [min]="0"
              [max]="100"
              [(ngModel)]="scPaceLevel"
              (change)="setSCSpeed()"
              style="width: 80%"
            ></mat-slider>
            +
            <!-- <button mat-raised-button (click)="stopSafetyCar()">
              <mat-icon> stop </mat-icon>
            </button> -->
            <!-- <button mat-raised-button (click)="startSafetyCar()">
              <mat-icon> play </mat-icon>
            </button> -->
          </div>
        </div>
      </div>
      <div id="statusPanel">
        <div id="statusBox">
          <app-session-status></app-session-status>
        </div>
      </div>
      <div id="crashPanel">
        <div id="crashCarSelection">
          <div class="driverUserRow jaguarFont" [style.margin-bottom]="'5px'">
            <div id="duNum">Driver</div>
            <div id="duUser">User</div>
            <div id="duActions">Actions</div>
          </div>
          <div
            *ngFor="let driverUser of driverUsers"
            class="driverUserRow jaguarFont"
          >
            <div
              id="duNum"
              [style.color]="
                !crashedCars
                  ? 'white'
                  : crashedCars.includes(driverUser.driverNum)
                  ? 'rgb(80,80,80)'
                  : 'rgb(255,255,255)'
              "
            >
              {{ driverUser.driver }}
            </div>
            <div
              id="duUser"
              [style.color]="
                crashedCars
                  ? crashedCars.includes(driverUser.driverNum)
                    ? 'rgb(80,80,80)'
                    : 'rgb(255,255,255)'
                  : 'white'
              "
            >
              {{ driverUser.user }}
            </div>
            <div id="duActions">
              <button
                mat-raised-button
                [style.visibility]="
                  driverUser.user != 'AI' ? 'visible' : 'hidden'
                "
                (click)="kick(driverUser.driverNum)"
                class="duActionButton jaguarFont"
              >
                kick
              </button>
              <button
                mat-raised-button
                (click)="crash(driverUser.driverNum)"
                class="duActionButton jaguarFont"
                [style.visibility]="
                  crashedCars && crashedCars.includes(driverUser.driverNum)
                    ? 'hidden'
                    : 'visible'
                "
              >
                crash
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="rcPanel">
        <div id="rcTitle" class="jaguarFont">RACE CONTROL</div>
        <form class="example-form">
          <mat-form-field appearance="none" class="example-full-width">
            <mat-label id="rcLabel"
              >&nbsp;Enter Race Control Message...</mat-label
            >
            <input
              matInput
              id="rcInput"
              [(ngModel)]="rcMessage"
              (keydown.enter)="sendRCMessage()"
              name="rcMessage"
            />
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
</app-main-nav>
