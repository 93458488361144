import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SocketioService } from "../../services/socketio/socketio.service";
import { AdminGuard } from "../../services/guard/admin.guard";
import { AuthService } from "../../services/auth/auth.service";
import { AdminResetDialogueComponent } from "../../components/admin-widgets/admin-reset-dialogue/admin-reset-dialogue.component";
import { MatDialog } from "@angular/material/dialog";
import { ColoursService } from "../../services/colours/colours.service";

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"],
})
export class MainNavComponent implements OnInit {
  title = "RaceToolUI";
  showAdminPages = false;
  pTitle: any;
  href: any;

  constructor(
    private router: Router,
    private colours: ColoursService,
    public _guard: AdminGuard,
    public sio: SocketioService,
    public auth: AuthService,
    public dialog: MatDialog
  ) {
    this.setPermissionsLvl();
  }

  ngOnInit() {
    this.setTitle();
  }

  setPermissionsLvl() {
    this.appPages = this.appPages.filter((x) =>
      this._guard.permissions[this.auth.role].includes(x.url)
    );
    this.adminPages = this.adminPages.filter((x) =>
      this._guard.permissions[this.auth.role].includes(x.url)
    );
  }

  openClearCacheDialogue() {
    this.dialog.open(AdminResetDialogueComponent, { disableClose: true });
  }

  setTitle() {
    this.href = this.router.url;
    let check = false;
    for (let x of this.appPages) {
      if (x.url === this.href) {
        this.checkMenu(x.title);
        check = true;
      }
    }
    if (!check) {
      for (let x of this.adminPages) {
        if (x.url === this.href) {
          this.checkMenu(x.title);
          check = true;
        }
      }
    }
  }

  checkMenu(title: string) {
    this.pTitle = title;
  }

  public adminPages = [];

  public appPages = [{ title: "HOME", url: "/home", icon: "home" }];
}
