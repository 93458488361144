<div
  id="energiesPanel"
  class="jaguarFont"
  [style.border-color]="energies.liveUpdatingEnergy ? 'crimson' : 'black'"
>
  <div
    id="liveBar"
    [style.visibility]="energies.liveUpdatingEnergy ? 'visible' : 'hidden'"
  >
    <mat-icon>fiber_manual_record</mat-icon>
    <div id="liveText">&nbsp;LIVE</div>
  </div>
  <div id="energiesWrapper">
    <div class="energyCell" id="energiesHeader">
      <div class="posCol">P</div>
      <div class="nameCol">TLA</div>
      <div class="energyCol">E</div>
      <div class="attackCol">ATK</div>
      <div class="hasChargedCol" *ngIf="status.status.pitStop">CHRG</div>
    </div>
    <div *ngFor="let driver of energies.energies" class="energyCell">
      <div id="posText" class="posCol">{{ driver.pos }}</div>
      <div id="energyName" class="nameCol" [style.color]="driver.colour">
        {{ driver.name }}
      </div>
      <div
        id="energyEnergy"
        class="energyCol stage-1-hidden"
        [style.color]="
          energies.liveUpdatingEnergy ? 'white' : 'rgb(150,150,150)'
        "
      >
        {{ driver.energy }}%
      </div>

      <div
        id="energyAttacks"
        class="attackCol"
        [style.color]="
          energies.attacks[driver.num] &&
          energies.attacks[driver.num].attackMode
            ? 'rgb(230, 100, 255)'
            : 'white'
        "
      >
        {{
          energies.attacks[driver.num]
            ? energies.attacks[driver.num]?.attackModeCounter
            : 0
        }}/2
      </div>

      <div
        id="energyHasCharged"
        class="hasChargedCol"
        *ngIf="status.status.pitStop"
      >
        {{
          energies.attacks[driver.num]?.hasCharged && status.status.pitStop
            ? "✓"
            : ""
        }}
      </div>
    </div>
  </div>
</div>
