<div
  id="classContainer"
  [ngClass]="{
    'jaguar-theme': auth.userOptions['theme'] == 'Jaguar',
    'envision-theme': auth.userOptions['theme'] == 'Envision'
  }"
>
  <div
    id="main"
    style="background-size: cover"
    [ngStyle]="{
      'background-image': 'url(./assets/images/background.jpg)'
    }"
  >
    <div id="sideBox">
      <div id="paddedContainer">
        <ng-content>
          <!-- App content goes here -->
        </ng-content>
      </div>
    </div>
  </div>
</div>
