import { Injectable } from "@angular/core";
import { SocketioService } from "../socketio/socketio.service";

@Injectable({
  providedIn: "root",
})
export class StandingsService {
  constructor(private sio: SocketioService) {
    this.reset();
    this.sioEventListeners();
    this.sioJoinRooms();
  }

  driverToPos = {};

  sioEventListeners() {
    this.sio.server.on("standingsUpdate", (standings: object) => {
      this.driverToPos = standings;
    });
  }

  sioJoinRooms() {
    this.sio.joinRoom(this.sio.server, "standings");
  }

  reset() {}
}
