/* eslint-disable no-prototype-builtins */
import { Component, OnInit } from "@angular/core";
import { urls } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { SocketioService } from "../../services/socketio/socketio.service";
import { EnergiesService } from "../../services/energies/energies.service";
import { SessionStatusService } from "../../services/session-status/session-status.service";
import { ConfigsService } from "../../services/configs/configs.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import axios from "axios";

@Component({
  selector: "app-race-manager-page",
  templateUrl: "./race-manager-page.component.html",
  styleUrls: ["./race-manager-page.component.scss"],
})
export class RaceManagerPageComponent implements OnInit {
  constructor(
    public auth: AuthService,
    private sio: SocketioService,
    private energies: EnergiesService,
    public status: SessionStatusService,
    public configs: ConfigsService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          console.log("log back in!");
          this.router.navigateByUrl("/login");
        }
        return error;
      }
    );
  }

  events = {};

  showLiveEnergies: boolean;

  scPaceLevel: number;

  safetyCarComingIn: boolean;

  cars: Array<string>;

  selectedCrashCars: Array<string>;

  rcMessage: string;

  driverUsers: Array<{ user: string; driver: string; driverNum: string }>;
  energyArray: {
    name: string;
    num: string;
    pos: number;
    energy: number;
    colour: string;
  }[];
  driverToPosMap: { [key: string]: number } = {};

  crashedCars: Array<string>;

  saveSession: boolean;

  sessionName: string;
  cachedFlagType: string;

  ngOnInit(): void {
    this.sioEventListeners();
    this.sioJoinRooms();
    this.subEvents();
    this.configs.getConfigs();
    this.updateDriverUsers();
    this.getCars();
  }

  ngOnDestroy(): void {
    this.sioLeaveRooms();
    this.sioRemoveEventListeners();
    this.unsubEvents();
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "raceToolStatus");
    this.sio.joinRoom(this.sio.server, "driverUsers");
    this.sio.joinRoom(this.sio.server, "crashes");
    this.sio.joinRoom(this.sio.server, "setNameCallback");
  }

  sioLeaveRooms(): void {
    this.sio.leaveRoom(this.sio.server, "raceToolStatus");
    this.sio.leaveRoom(this.sio.server, "driverUsers");
    this.sio.leaveRoom(this.sio.server, "crashes");
    this.sio.leaveRoom(this.sio.server, "setNameCallback");
  }

  sioEventListeners(): void {
    this.sio.server.on(
      "driverUsersList",
      (list: Array<{ user: string; driver: string; driverNum: string }>) => {
        this.driverUsers = list;
        this.updateDriverUsers();
      }
    );
    this.sio.server.on(
      "driverJoin",
      (driverObj: { driver: string; user: string }) => {
        for (const duObj of this.driverUsers) {
          if (duObj.driver === driverObj.driver) {
            duObj.user = driverObj.user;
          }
        }
      }
    );
    this.sio.server.on("driverLeft", (driverObj: { driver: string }) => {
      for (const duObj of this.driverUsers) {
        if (duObj.driver === driverObj.driver) {
          duObj.user = "AI";
        }
      }
    });
    this.sio.server.on("crash", (driverNum: string) => {
      if (!this.crashedCars) {
        this.crashedCars = [];
      }
      this.crashedCars.push(driverNum);
    });
    this.sio.server.on("crashList", (crashedCars: Array<string>) => {
      this.crashedCars = crashedCars;
    });
    this.sio.server.on("saveSessionUpdate", (saveSessionState: boolean) => {
      this.saveSession = saveSessionState;
    });
    this.sio.server.on("finishedSettingName", () => {
      if (!this.cachedFlagType) return;
      this.sio.writeAPI("FLAG", { flagType: this.cachedFlagType });
      this.cachedFlagType = "";
    });
  }

  sioRemoveEventListeners(): void {
    this.sio.server.off("liveUpdatingEnergyState");
    this.sio.server.off("raceToolReset");
    this.sio.server.off("driverUsersList");
    this.sio.server.off("driverJoin");
    this.sio.server.off("driverLeft");
    this.sio.server.off("crash");
    this.sio.server.off("crashList");
  }

  subEvents(): void {
    this.events["raceToolReset"] = this.status.raceToolResetEvent.subscribe(
      () => {
        this.snackBar.open(`Race tool has been reset!`, "", { duration: 5000 });
        this.safetyCarComingIn = false;
        this.showLiveEnergies = false;
        this.configs.getConfigs();
        this.cachedFlagType = "";
      }
    );
    this.events["liveUpdatingState"] =
      this.energies.liveUpdatingStateEvent.subscribe((state: boolean) => {
        this.showLiveEnergies = state;
      });
    this.events["energies"] = this.energies.energiesEvent.subscribe(
      (
        energies: {
          name: string;
          num: string;
          pos: number;
          energy: number;
          colour: string;
        }[]
      ) => {
        this.energyArray = energies;
        this.updateDriverUsers();
      }
    );
    this.events["statusUpdate"] = this.status.statusUpdateEvent.subscribe(
      (data) => {
        if (data.hasOwnProperty("liveEnergies")) {
          this.showLiveEnergies = data["liveEnergies"];
        }
        if (data.hasOwnProperty("safetyCarPace")) {
          this.scPaceLevel = data.safetyCarPace;
        }
        if (data.hasOwnProperty("safetyCarComingIn")) {
          this.safetyCarComingIn = data["safetyCarComingIn"];
        }
        if (data.hasOwnProperty("saveSession")) {
          this.saveSession = data["saveSession"];
        }
        if (data.hasOwnProperty("sessionName")) {
          this.sessionName = data["sessionName"];
        }
      }
    );
    this.events["safetyCarIn"] = this.status.safetyCarInEvent.subscribe(
      (statusObj: { status: string }) => {
        if (statusObj["status"] === "IN") {
          this.safetyCarComingIn = false;
        } else {
          this.snackBar.open(`Safety car coming in this lap.`, "", {
            duration: 5000,
          });
          this.safetyCarComingIn = true;
        }
      }
    );
    this.events["sessionName"] = this.status.sessionNameEvent.subscribe(
      (data) => {
        this.sessionName = data["name"];
      }
    );
  }

  unsubEvents(): void {
    if (this.events["raceToolReset"] != undefined)
      this.events["raceToolReset"].unsubscribe();
    if (this.events["liveUpdatingState"] != undefined)
      this.events["liveUpdatingState"].unsubscribe();
    if (this.events["energies"] != undefined)
      this.events["energies"].unsubscribe();
    if (this.events["statusUpdate"] != undefined)
      this.events["statusUpdate"].unsubscribe();
    if (this.events["safetyCarIn"] != undefined)
      this.events["safetyCarIn"].unsubscribe();
    if (this.events["sessionName"] != undefined)
      this.events["sessionName"].unsubscribe();
  }

  getCars(): void {
    axios
      .get(urls["server"] + "api/racetool/get-cars", this.auth.apiConfig)
      .then((resp) => {
        this.cars = resp.data;
        return null;
      })
      .catch(() => {
        /* */
      });
  }

  reset(): void {
    this.sio.writeAPI("RESET", {
      id: this.configs.configMap[this.configs.chosenConfig],
    });
  }

  setSaveSession(): void {
    this.sio.writeAPI("SET_SAVING", { state: this.saveSession });
  }

  goToImportGenerator(): void {
    this.router.navigateByUrl("/import-generator");
  }

  goToImportGeneratorNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/import-generator", "_blank");
  }

  goToLoadSessionMenu(): void {
    this.router.navigateByUrl("/load-session");
  }

  goToLoadSessionMenuNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/load-session", "_blank");
  }

  setLiveEnergies(): void {
    if (this.showLiveEnergies) {
      this.sio.writeAPI("SHOW_ENERGIES", {});
    } else {
      this.sio.writeAPI("STOP_SHOWING_ENERGIES", {});
    }
  }

  flag(type: string): void {
    if (type == "GREEN" && this.status.status.currentFlag == "NONE") {
      this.sio.writeAPI("SET_NAME", { name: this.sessionName });
      this.cachedFlagType = type;
    } else {
      this.sio.writeAPI("FLAG", { flagType: type });
    }
  }

  setSCSpeed(): void {
    this.sio.writeAPI("SC_PACE", { pace: this.scPaceLevel });
  }

  stopSafetyCar(): void {
    this.sio.writeAPI("SC_PACE", { speed: 1 });
  }

  crash(car: string): void {
    this.sio.writeAPI("CRASH", { cars: car });
  }

  sendRCMessage(): void {
    this.sio.writeAPI("RC", { message: this.rcMessage });
    this.rcMessage = "";
  }

  kick(driverNum: string): void {
    this.sio.server.emit("kick", driverNum);
  }

  home(): void {
    this.router.navigateByUrl("/home");
  }

  homeNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/home", "_blank");
  }

  updateDriverUsers(): void {
    if (!this.driverUsers) return;
    this.driverToPosMap = {};
    for (const driverEnergyObj of this.energyArray) {
      this.driverToPosMap[`${driverEnergyObj["num"]}`] = driverEnergyObj["pos"];
    }
    this.driverUsers.sort(
      (a, b) =>
        this.driverToPosMap[a.driverNum] - this.driverToPosMap[b.driverNum]
    );
  }
}
