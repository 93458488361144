import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import axios from "axios";
import { urls } from "../../../environments/environment";
import { Router } from "@angular/router";
import { DriverService } from "../../services/driver/driver.service";
import { SocketioService } from "../../services/socketio/socketio.service";
import { SessionStatusService } from "../../services/session-status/session-status.service";

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  start: boolean;
  drivers = {};
  cars = [];
  driverTiles = [];
  driverEnabled = [];
  doneDisabling = false;

  VIEWER_ONLY_ROLES = ["Viewer"];
  RACE_MANAGER_ROLES = ["Developer"];

  constructor(
    public auth: AuthService,
    private router: Router,
    private driver: DriverService,
    private sio: SocketioService,
    public status: SessionStatusService
  ) {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          console.log("log back in!");
          this.router.navigateByUrl("/login");
        }
        return error;
      }
    );
  }

  ngOnInit(): void {
    this.sioEventListeners();
    this.sioJoinRooms();
    this.getCars();
  }

  ngOnDestroy(): void {
    this.sioLeaveRooms();
    this.sioRemoveEventListeners();
  }

  sioEventListeners(): void {
    this.sio.server.on(
      "driverSelected",
      (disabledDriverNums: Array<string>) => {
        this.doneDisabling = false;
        for (const num of disabledDriverNums) {
          this.disableDriver(num);
        }
        this.doneDisabling = true;
      }
    );
    this.sio.server.on("driverDeselected", (deselectedDriver: string) => {
      this.enableDriver(deselectedDriver);
    });
  }

  sioRemoveEventListeners(): void {
    this.sio.server.off("driverSelected");
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "driverSelections");
  }

  sioLeaveRooms(): void {
    this.sio.leaveRoom(this.sio.server, "driverSelections");
  }

  getDrivers(): void {
    axios
      .get(urls["server"] + "api/racetool/get-drivers", this.auth.apiConfig)
      .then(async (resp) => {
        this.drivers = {};
        for (const carNum of this.cars) {
          this.drivers[carNum] = resp.data[carNum];
        }
        for (const [driverNum, driverDetails] of Object.entries(this.drivers)) {
          this.driverTiles.push({
            text: driverDetails["name"],
            num: driverNum,
            cols: 1,
            rows: 1,
            color: driverDetails["team"]["colour"],
            team: driverDetails["team"]["name"],
          });
          console.log(this.driverTiles);
          this.driverTiles.sort((a, b) => {
            if (a.team < b.team) return -1;
            if (a.team > b.team) return 1;
            return 0;
          });
          if (this.driverEnabled[driverNum] === undefined) {
            this.driverEnabled[driverNum] = true;
          }
        }
        return true;
      })
      .catch(() => {
        console.error("Error getting drivers.");
      });
  }

  async getCars(): Promise<void> {
    axios
      .get(urls["server"] + "api/racetool/get-cars", this.auth.apiConfig)
      .then((resp) => {
        this.cars = resp.data;
        this.getDrivers();
        return true;
      })
      .catch(() => {
        console.error("Error getting cars.");
      });
  }

  selectDriver(driverDetails: { num: string; text: string }): void {
    this.driver.selectDriver(driverDetails["num"], driverDetails["text"]);
    this.router.navigateByUrl("/driver");
  }

  selectDriverNewTab(
    driverDetails: { num: string; text: string },
    event: MouseEvent
  ): void {
    if (event.button !== 1) return;
    this.driver.selectDriver(driverDetails["num"], driverDetails["text"]);
    window.open("/driver", "_blank");
  }

  disableDriver(num: string): void {
    this.driverEnabled[num] = false;
  }

  enableDriver(num: string): void {
    this.driverEnabled[num] = true;
  }

  raceManager(): void {
    this.router.navigateByUrl("race-manager");
  }

  raceManagerNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/race-manager", "_blank");
  }

  viewer(): void {
    this.router.navigateByUrl("viewer");
  }

  viewerNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/viewer", "_blank");
  }
}
