import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Race Tool";
  status = "ONLINE"; //initializing as online by default
  isConnected = true;

  constructor(public router: Router) {}

  showMenu = true;
}
