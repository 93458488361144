import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DriverService {
  constructor() {}

  selectDriver(driverNum: string, driverName: string) {
    sessionStorage.setItem("driver", driverNum);
    sessionStorage.setItem("driverName", driverName);
  }

  getSelectedDriver(): string {
    return sessionStorage.getItem("driver");
  }

  getSelectedName(): string {
    return sessionStorage.getItem("driverName");
  }
}
