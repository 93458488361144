import { Injectable } from "@angular/core";
import { SocketioService } from "../socketio/socketio.service";
import { SessionStatusService } from "../session-status/session-status.service";
import { Subject } from "rxjs";

type RCObj = {
  backgroundColor: string;
  foregroundColor: string;
  text: string;
  dt: Date;
  duration: number;
};

@Injectable({
  providedIn: "root",
})
export class RaceControlService {
  constructor(
    private sio: SocketioService,
    private status: SessionStatusService
  ) {
    this.sioEventListeners();
    this.sioJoinRooms();
    this.subEvents();
  }

  private raceControlSubject = new Subject<RCObj>();
  raceControlEvent = this.raceControlSubject.asObservable();
  private raceControlFlagSubject = new Subject<RCObj>();
  raceControlFlagEvent = this.raceControlFlagSubject.asObservable();
  private scrollSubject = new Subject();
  scrollEvent = this.scrollSubject.asObservable();

  events = {};
  raceControlMessages: Array<RCObj>;

  sioEventListeners(): void {
    this.sio.server.on("rcMsg", (msgObj: RCObj) => {
      const shouldScroll = this.isScrollAtBottom();
      const dtObj = new Date(msgObj["dt"]);
      msgObj["dt"] = dtObj;
      this.raceControlSubject.next(msgObj);
      this.raceControlMessages.push(msgObj);
      this.sortRCMessages();
      if (shouldScroll) {
        this.scrollSubject.next();
      }
    });
    this.sio.server.on("rcFlag", (flagObj: RCObj) => {
      const dtObj = new Date(flagObj["dt"]);
      flagObj["dt"] = dtObj;
      this.raceControlFlagSubject.next(flagObj);
    });
    this.sio.server.on("allRCMsgs", (msgArray: Array<RCObj>) => {
      const msgArrayWithDate = [];
      for (const msg of msgArray) {
        const dtObj = new Date(msg["dt"]);
        msg["dt"] = dtObj;
        msgArrayWithDate.push(msg);
      }
      this.raceControlMessages = msgArrayWithDate;
      this.sortRCMessages();
      this.scrollSubject.next();
    });
  }

  sioOffEventListeners(): void {
    this.sio.server.off("rcMsg");
    this.sio.server.off("rcFlag");
    this.sio.server.off("allRCMsgs");
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "raceControl");
  }

  sioLeaveRooms(): void {
    this.sio.leaveRoom(this.sio.server, "raceControl");
  }

  subEvents(): void {
    this.events["raceToolReset"] = this.status.raceToolResetEvent.subscribe(
      () => this.reset()
    );
  }

  unsubEvents(): void {
    if (this.events["raceToolReset"] != undefined)
      this.events["raceToolReset"].unsubscribe();
  }

  reset(): void {
    this.raceControlMessages = [];
  }

  sortRCMessages(): void {
    this.raceControlMessages.sort((a, b) => a.dt.getTime() - b.dt.getTime());
  }

  isScrollAtBottom(): boolean {
    const rcPanel = document.getElementById("rcMessages");
    if (rcPanel === null) return false;
    return (
      rcPanel.scrollHeight - rcPanel.scrollTop <= rcPanel.clientHeight * 1.03
    );
  }
}
