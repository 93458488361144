import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-raceplot-page",
  templateUrl: "./raceplot-page.component.html",
  styleUrls: ["./raceplot-page.component.scss"],
})
export class RaceplotPageComponent implements OnInit {
  constructor() {
    document.body.style.overflow = "hidden";
  }

  ngOnInit(): void {
    /* */
  }
}
