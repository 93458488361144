<div
  class="container"
  *ngIf="sessionStatus.active && !data"
  [ngStyle]="{
    'background-color': flagColours.hasOwnProperty(
      sessionStatus.status.currentFlag
    )
      ? flagColours[sessionStatus.status.currentFlag]
      : 'white'
  }"
>
  <div class="jaguarFont statusBox">
    <div class="lapText">Lap</div>
    <div class="lapNum">
      {{ sessionStatus.status.currentLap }} /
      {{ sessionStatus.status.finalLaps }}
    </div>
  </div>
</div>
<div
  class="container"
  *ngIf="data"
  [ngStyle]="{
    'background-color': flagColours.hasOwnProperty(data.flag)
      ? flagColours[data.flag]
      : 'white'
  }"
>
  <div class="jaguarFont statusBox">
    <div class="lapText">Lap</div>
    <div class="lapNum">
      {{ sessionStatus.status.currentLap }} /
      {{ sessionStatus.status.finalLaps }}
    </div>
  </div>
</div>
