import { Component, OnInit } from "@angular/core";
import { SocketioService } from "../../../services/socketio/socketio.service";
import { FunctionsService } from "../../../services/functions/functions.service";
import Highcharts from "highcharts";
import { Chart } from "angular-highcharts";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  constructor(private sio: SocketioService, private func: FunctionsService) {
    /* */
  }
  gpsRacingLine: { lon: number[]; lat: number[] } = { lon: [], lat: [] };
  tLap: number[] = [];
  maxTLap = 0;

  maxLon = 0;
  maxLat = 0;
  minLon = 0;
  minLat = 0;

  zones: number[][] = [];
  highlightedIndices: { [key: number]: boolean } = {};
  firstPointClicked = false;
  generatedZones: { start: number; end: number }[] = [];

  chartRef: Highcharts.Chart;

  chart = new Chart({
    chart: {
      type: "scatter",
      backgroundColor: "rgb(205, 205, 205)",
      animation: false,
      zooming: {
        type: "xy",
        resetButton: {
          theme: {
            style: {
              display: "none",
            },
          },
        },
      },
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 1,
    },
    title: { text: "" },
    yAxis: {
      title: { text: "" },
      startOnTick: false,
      endOnTick: false,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      visible: false,
    },
    xAxis: {
      type: "category",
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      labels: {},
      max: 1,
      min: 0,
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
        animation: false,
        stickyTracking: false,
        point: {
          events: {
            click: (event: PointerEvent) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const idx = event.point.index;
              const x = this.chartRef.series[0].data[idx].x;
              const y = this.chartRef.series[0].data[idx].y;
              this.chartRef.series[0].data[idx].update(
                {
                  x: x,
                  y: y,
                  color: "gold",
                },
                true
              );
              this.highlightedIndices[idx] = true;
              if (this.firstPointClicked) {
                this.handleSecondClick(idx);
              } else {
                this.zones.push([idx]);
                this.firstPointClicked = true;
              }
            },
          },
        },
      },
    },
    credits: { enabled: false },
    series: [],
    legend: {
      enabled: false,
    },
  });

  ngOnInit(): void {
    this.sioJoinRooms();
    this.sioEventHandlers();
    this.resetChart();
    this.updateMap();
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "gps");
  }

  sioEventHandlers(): void {
    this.sio.server.on(
      "racingLine",
      (data: { lon: number[]; lat: number[] }) => {
        this.gpsRacingLine = data;
        this.resetChart();
        this.updateMap();
      }
    );
    this.sio.server.on("tLap", (data: number[]) => {
      this.tLap = data;
      this.maxTLap = Math.max(...data);
    });
  }

  handleSecondClick(idx: number): void {
    const currZone = this.zones[this.zones.length - 1];
    currZone.push(idx);
    const idx1 = currZone[0];
    const idx2 = currZone[1];
    const currIndices = {};
    if (idx1 < idx2) {
      for (let i = idx1 + 1; i < idx2; i++) {
        currIndices[i] = true;
      }
    } else {
      for (let i = idx1 + 1; i < this.gpsRacingLine.lon.length; i++) {
        currIndices[i] = true;
      }
      for (let i = 0; i < idx2; i++) {
        currIndices[i] = true;
      }
    }
    const diff = MapComponent.highlightDiff(
      this.highlightedIndices,
      currIndices
    );
    for (const [idx, val] of Object.entries(diff)) {
      this.chartRef.series[0].data[idx].update(
        {
          x: this.chartRef.series[0].data[idx].x,
          y: this.chartRef.series[0].data[idx].y,
          color: val ? "gold" : "lightblue",
        },
        false
      );
    }
    this.func.merge(this.highlightedIndices, currIndices);
    this.chartRef.redraw();
    this.firstPointClicked = false;
  }

  resetChart(): void {
    this.chart.ref$.subscribe((chart) => {
      this.chartRef = chart;
    });
    this.zones = [];
    if (!this.chartRef) return;
    while (this.chartRef.series.length > 0) {
      this.chartRef.series[0].remove(false);
    }
  }

  updateMap(): void {
    if (!this.chartRef || this.gpsRacingLine.lat.length === 0) return;
    this.maxLon = Math.max(...this.gpsRacingLine.lon);
    this.maxLat = Math.max(...this.gpsRacingLine.lat);
    this.minLon = Math.min(...this.gpsRacingLine.lon);
    this.minLat = Math.min(...this.gpsRacingLine.lat);
    const normalizedLon = this.gpsRacingLine.lon.map(
      (lon) => (lon - this.minLon) / (this.maxLon - this.minLon)
    );
    const normalizedLat = this.gpsRacingLine.lat.map(
      (lat) => (lat - this.minLat) / (this.maxLat - this.minLat)
    );
    const mapData = normalizedLon.map((lon, i) => [lon, normalizedLat[i]]);
    this.chartRef.addSeries({
      name: "Racing Line",
      type: "scatter",
      data: mapData,
      marker: {
        radius: 4,
      },
      cursor: "pointer",
    });
    this.chartRef.redraw();
  }

  resetZones(): void {
    this.firstPointClicked = false;
    for (const idx of Object.keys(this.highlightedIndices)) {
      this.chartRef.series[0].data[idx].update(
        {
          x: this.chartRef.series[0].data[idx].x,
          y: this.chartRef.series[0].data[idx].y,
          color: "#7cb5ec",
        },
        false
      );
    }
    this.chartRef.redraw();
    this.highlightedIndices = {};
    this.zones = [];
  }

  generateZones(): void {
    const posZones = [];
    for (const zone of this.zones) {
      const minPos = this.tLap[zone[0]] / this.maxTLap;
      const maxPos = this.tLap[zone[1]] / this.maxTLap;
      const posZone = { start: minPos, end: maxPos };
      posZones.push(posZone);
    }
    this.generatedZones = posZones;
  }

  static highlightDiff(
    curr: { [key: string]: boolean },
    next: { [key: string]: boolean }
  ): { [key: string]: boolean } {
    const diff = {};
    for (const [key, value] of Object.entries(next)) {
      if (curr[key] !== value) diff[key] = value;
    }
    return diff;
  }
}
