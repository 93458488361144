<app-welcome-page>
  <form>
    <h1 class="jaguarFont">Login</h1>
    <div id="inputFields">
      <input #usr type="text" placeholder="Username or Email" required />
      <input
        #pwd
        type="password"
        placeholder="Password"
        required
        (keyup.enter)="auth.authenticatePwd(usr.value, pwd.value)"
      />
    </div>
    <button
      id="loginBtn"
      mat-raised-button
      color="primary"
      (click)="auth.authenticatePwd(usr.value, pwd.value)"
    >
      Login
    </button>
    <div id="helpContainer">
      <div class="help">
        Don't have an account?
        <a href="https://strategy.jagracingtools.com/new-user">Create one</a>
      </div>
      <div class="help">
        Forgot password?
        <a href="https://strategy.jagracingtools.com/forgot-password"
          >Reset it</a
        >
      </div>
    </div>
  </form>
</app-welcome-page>
