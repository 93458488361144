<div class="container">
  <div id="optionsRow">
    <mat-form-field appearance="none" color="accent" id="refSelect">
      <mat-label>Reference</mat-label>
      <mat-select [(value)]="selectedRef">
        <mat-option
          *ngFor="let opt of refOpts"
          [value]="opt"
          (click)="changeRef()"
        >
          {{ opt }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      id="lapTimeInput"
      [style.visibility]="selectedRef === 'Laptime' ? 'visible' : 'hidden'"
    >
      <mat-form-field appearance="outline" color="accent" id="lapTimeFormField">
        <mat-label>Reference Lap Time (s)</mat-label>
        <input
          matInput
          type="text"
          [disabled]="selectedRef != 'Laptime'"
          [(ngModel)]="lapTimeInput"
          (keydown.enter)="updateRefLapTime()"
        />
      </mat-form-field>
    </div>
    <div id="attackSelect">
      Show Attack Gap: &nbsp;
      <div id="attackDriverList">
        <div *ngFor="let driver of attackHighlightOptions" class="driverAttack">
          <div class="attackDriverNum">
            {{ driver.num }}
          </div>
          <mat-checkbox
            [(ngModel)]="driver.selected"
            (ngModelChange)="updateAttackDriverList()"
          ></mat-checkbox>
        </div>
      </div>
    </div>
    <div id="attackGap">
      Attack Gap:&nbsp;
      <mat-form-field
        appearance="outline"
        color="accent"
        id="attackGapFormField"
      >
        <mat-label>Attack Gap (s)</mat-label>
        <input
          matInput
          type="text"
          id="attackGapInput"
          [(ngModel)]="attackModeGap"
          (keydown.enter)="updateAttackModeGap()"
        />
      </mat-form-field>
    </div>
    <div id="yAxisMin">
      yMin:
      <mat-form-field appearance="none" class="axisFormField">
        <input
          id="enoInput"
          matInput
          (keydown.enter)="setYLimits()"
          [(ngModel)]="yMin"
        />
      </mat-form-field>
    </div>
    <div id="yAxisMax">
      yMax:
      <mat-form-field appearance="none" class="axisFormField">
        <input
          id="enoInput"
          matInput
          (keydown.enter)="setYLimits()"
          [(ngModel)]="yMax"
        />
      </mat-form-field>
    </div>
    <div id="zoomReset">
      <button mat-raised-button color="accent" (click)="resetZoom()">
        Reset Zoom
      </button>
    </div>
  </div>
  <div [chart]="chart" id="chart" (dblclick)="resetZoom()"></div>
</div>
