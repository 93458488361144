import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { DriverService } from "../../services/driver/driver.service";
import { Router } from "@angular/router";
import { SocketioService } from "../../services/socketio/socketio.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../services/auth/auth.service";
import {
  SessionStatusService,
  Status,
} from "../../services/session-status/session-status.service";
import { StandingsService } from "../../services/standings/standings.service";
import { RaceControlService } from "../../services/race-control/race-control.service";
import { ColoursService } from "../../services/colours/colours.service";
import { order } from "../../../assets/garageOrder";
import axios from "axios";
import { EnergiesService } from "../../services/energies/energies.service";

const flags = ["NONE", "GREEN", "FULL_YELLOW", "SAFETY_CAR", "RED"];
type flag = (typeof flags)[number];

const MAX_ENO = 0.5;
const MIN_ENO = -0.5;

const FLAG_COLOUR_MAP: Map<flag, string> = new Map([
  ["NONE", "white"],
  ["GREEN", "green"],
  ["FULL_YELLOW", "yellow"],
  ["SAFETY_CAR", "orange"],
  ["RED", "red"],
]);

const GAP_RANGE = 3;

type DriverStatus = {
  frontierFactor: number;
  lapNoiseFactor: number;
  energyOffset: number;
  aggressiveness: number;
  regenPower: number;
  lapOffset: number;
  activeLapTime: number;
  regularActiveLapTime: number;
  attackModeMode: string;
  attackMode: boolean;
  attackModeSet: boolean;
  attackOpposite: boolean;
  followAttack: boolean;
  attackDriverInFront: string;
  attackModeStartTime: number;
  attackModeDuration: number;
  attackModeCounter: number;
  didAttack: { lap: number; state: boolean };
  pitIn: boolean;
  hasCharged: boolean;
  greenCode: string;
  orangeCode: string;
  autoSendCodes: boolean;
  baselineEnergyConsumption: number;
  adjustedEnergyConsumption: number;
  activeEnergyConsumption: number;
  segmentEnergyConsumption: number;
  currentSegmentENO: number;
  prevOTPointAEC: number;
  prevSegEnergyConsumed: number;
  defensiveEnergyConsumed: number;
  nextOTSegmentAdjustedEnergyConsumption: number;
  flatOutAEC: number;
  fullEcoAEC: number;
  currentLoop: number;
  currentLap: number;
  displayCurrentLap: number;
  pastLap: { energyConsumed: number; energyTargetDelta: number };
  predictedConsumption: number;
  stopped: boolean;
  crashed: boolean;
  attacking: boolean;
  pitting: boolean;
  allowingOvertake: boolean;
  allowOvertake: boolean;
  beingOvertaken: boolean;
  overtaking: boolean;
  overtakenSpeed: number | null;
  overtakingTarget: string;
  hasBeenOvertaken: boolean;
  ghostLevel: number;
  crossedOT: boolean;
  settingSCPace: boolean;
  leaderSCLapTime: number;
  leaderSCPace: number;
  overtakingForbidden: boolean;
  overtakingDisabled: boolean;
  defending: boolean;
  deploy: boolean;
  firstTow: number;
  defensiveOTHistory: number[];
  currentSoc: number;
  currentEnergy: number;
  projectedSegmentConsumptions: { [key: string]: number };
  teammate: string | null;
};

@Component({
  selector: "app-driver-page",
  templateUrl: "./driver-page.component.html",
  styleUrls: ["./driver-page.component.scss"],
})
export class DriverPageComponent implements OnInit {
  constructor(
    private driver: DriverService,
    private router: Router,
    private sio: SocketioService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    public status: SessionStatusService,
    public standings: StandingsService,
    public rc: RaceControlService,
    public colours: ColoursService,
    private cdRef: ChangeDetectorRef,
    public energies: EnergiesService
  ) {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          console.log("log back in!");
          this.router.navigateByUrl("/login");
        }
        return error;
      }
    );
  }

  events = {};

  selectedDriver: string;
  selectedDriverName: string;

  energyOffset: number;
  energyOffsetString: string;

  aggressiveness: number;

  regenPower: number;

  lapOffset: number;

  attackModeSet: boolean;
  attackModeOn: boolean;
  attackModeMode: string;
  attackTimer: number;
  attackStartTime: number;
  attackInterval: NodeJS.Timeout;
  possibleAttackModes = ["1+3", "2+2", "3+1"];
  attacksDone: number;
  attacksDoneString: string;
  attackZonePosition: number;
  attackZoneTransformString: string;
  followAttack: boolean;
  attackOpposite: boolean;

  pitIn: boolean;
  pitting: boolean;
  hasCharged: boolean;

  nextOTSegmentAEC: number;
  flatOutAEC: number;
  fullEcoAEC: number;

  overtakingDisabled: boolean;
  overtakingForbidden: boolean;
  overtakingForbiddenByFlag = false;
  allowOvertake: boolean;

  defending: boolean;
  deploy: boolean;

  crossedOT: boolean;

  overtakePositions: Array<number>;
  overtakePositionStrings: Array<string>;
  preOvertakePositions: Array<{ pos: number; length: number }>;
  preOvertakePositionStrings: Array<{ leftCSS: string; widthCSS: string }>;
  isInsideOTSegmentBools: { [key: string]: boolean };
  isInsideAnyOTSegment = false;
  lastPreOTSegment: number;
  otSegmentColours: { [key: string]: string };

  ghostSegmentPositions: Array<{ pos: number; length: number }>;
  ghostSegmentPositionStrings: Array<{ leftCSS: string; widthCSS: string }>;
  isInsideGhostSegmentBools: { [key: string]: boolean };
  lastGhostSegment: number;
  ghostSegmentColours: { [key: string]: string };

  overtakingProbability = 0;

  inRangeFront: boolean;
  inRangeBehind: boolean;
  gapFront: string;
  gapBehind: string;
  standingsPos: number;

  overtaking: boolean;
  beingOvertaken: boolean;

  currentFlag: flag;
  flagColour: string;

  settingSCPace: boolean;
  scPace: number;

  currentLap: number;
  finalLaps: number;

  prevLapEnergyConsumed: number;
  prevLapEnergyTargetDelta: number;
  predictedConsumption: number;

  greenCode = "";
  orangeCode = "";
  animateGreen = false;
  animateOrange = false;
  autoSendCodes: boolean;

  echoMessage: string;
  echoTextBoxBorder = "solid 2px black";
  echoErrorTimeout: NodeJS.Timeout | undefined;
  echoCheck = false;
  echoCheckTimeout: NodeJS.Timeout | undefined;

  position = 0;
  soc: number;
  energy: number;
  energyString: string;

  targetEnergy: number;
  targetEnergyString: string;
  animateTargetEnergy = false;

  gaps: Array<{ num: string; gap: number; fractionalGap: number }>;

  gapMarkers: Array<number>;
  gapRange: number;

  trueDriver: boolean;

  drivers: Array<string>;

  crashed = false;
  animateCrash = false;

  teammate: string | null;

  ngOnInit(): void {
    this.selectedDriver = this.driver.getSelectedDriver();
    if (!this.selectedDriver) {
      this.goBack();
    }
    this.selectedDriverName = this.driver.getSelectedName();
    this.sioEventListeners();
    this.sioJoinRooms();
    this.subEvents();
    this.currentFlag = "NONE";
    this.flagColour = "white";
    this.attackZonePosition = this.status.status["attackZonePosition"];
    this.attackZoneTransformString = `${this.attackZonePosition * 100}%`;
    this.overtakePositions = this.status.status["overtakePositions"];
    this.overtakePositionStrings = [];
    for (const pos of this.overtakePositions) {
      this.overtakePositionStrings.push(`${pos * 100}%`);
    }
    this.preOvertakePositions = this.status.status["preOvertakePositions"];
    this.preOvertakePositionStrings = [];
    this.isInsideOTSegmentBools = {};
    this.lastPreOTSegment = 0;
    this.otSegmentColours = {};
    for (const otObj of this.preOvertakePositions) {
      this.preOvertakePositionStrings.push({
        leftCSS: `${otObj.pos * 100}%`,
        widthCSS: `${otObj.length * 100}%`,
      });
      this.isInsideOTSegmentBools[otObj.pos] = false;
      this.otSegmentColours[`${otObj.pos * 100}%`] = "#ffc0cc";
      if (otObj.pos > this.lastPreOTSegment) {
        this.lastPreOTSegment = otObj.pos;
      }
    }
    this.ghostSegmentPositions = this.status.status["ghostSegmentPositions"];
    this.ghostSegmentPositionStrings = [];
    this.isInsideGhostSegmentBools = {};
    this.lastGhostSegment = 0;
    this.ghostSegmentColours = {};
    for (const ghostObj of this.ghostSegmentPositions) {
      this.ghostSegmentPositionStrings.push({
        leftCSS: `${ghostObj.pos * 100}%`,
        widthCSS: `${ghostObj.length * 100}%`,
      });
      this.isInsideGhostSegmentBools[ghostObj.pos] = false;
      this.ghostSegmentColours[`${ghostObj.pos * 100}%`] = "#ffa500";
      if (ghostObj.pos > this.lastGhostSegment) {
        this.lastGhostSegment = ghostObj.pos;
      }
    }
    this.gapRange = GAP_RANGE;
    this.gapMarkers = [];
    for (let i = -GAP_RANGE; i <= GAP_RANGE; i++) {
      this.gapMarkers.push(i);
    }
    // this.status.resetSIO();
  }

  ngOnDestroy(): void {
    this.sioLeaveRooms();
    this.sioRemoveEventListeners();
    this.unsubEvents();
  }

  sioEventListeners(): void {
    this.sio.server.on("driverSelectionDenial", () => {
      this.snackBar.open(
        `Driver ${this.selectedDriver} has already been selected.`,
        "",
        { duration: 5000 }
      );
      this.goBack();
    });
    this.sio.server.on("driverKick", () => {
      this.snackBar.open(
        `You have been kicked from driver ${this.selectedDriver}.`,
        "",
        { duration: 5000 }
      );
      this.goBack();
    });
    this.sio.server.on("driverStatus", (statusObj: DriverStatus) => {
      this.energyOffset = statusObj.energyOffset;
      this.energyOffsetString = this.energyOffset.toFixed(2);
      this.regenPower = statusObj.regenPower;
      this.aggressiveness = statusObj.aggressiveness;
      this.lapOffset = statusObj.lapOffset;
      this.attackModeMode = statusObj.attackModeMode;
      this.attackModeOn = statusObj.attackMode;
      this.attackStartTime = statusObj.attackModeStartTime;
      this.attackModeSet = statusObj.attackModeSet;
      this.followAttack = statusObj.followAttack;
      this.attackOpposite = statusObj.attackOpposite;
      this.greenCode = statusObj.greenCode;
      this.orangeCode = statusObj.orangeCode;
      this.autoSendCodes = statusObj.autoSendCodes;
      this.targetEnergy = statusObj.baselineEnergyConsumption;
      this.targetEnergyString = this.targetEnergy.toFixed(2);
      this.soc = statusObj.currentSoc;
      this.energy = statusObj.currentEnergy;
      this.energyString = this.energy.toFixed(2);
      this.crashed = statusObj.crashed;
      this.currentLap = statusObj.displayCurrentLap;
      this.prevLapEnergyConsumed = statusObj.pastLap.energyConsumed;
      this.prevLapEnergyTargetDelta = statusObj.pastLap.energyTargetDelta;
      this.predictedConsumption = statusObj.predictedConsumption;
      this.nextOTSegmentAEC = statusObj.nextOTSegmentAdjustedEnergyConsumption;
      this.flatOutAEC = statusObj.flatOutAEC;
      this.fullEcoAEC = statusObj.fullEcoAEC;
      this.overtakingDisabled = statusObj.overtakingDisabled;
      this.overtakingForbidden = statusObj.overtakingForbidden;
      this.allowOvertake = statusObj.allowOvertake;
      this.defending = statusObj.defending;
      this.deploy = statusObj.deploy;
      this.overtaking = statusObj.overtaking;
      this.beingOvertaken = statusObj.beingOvertaken;
      this.settingSCPace = statusObj.settingSCPace;
      this.scPace = statusObj.leaderSCPace;
      this.hasCharged = statusObj.hasCharged;
      this.pitting = statusObj.pitting;
      this.pitIn = statusObj.pitIn;
      this.teammate = statusObj.teammate;
      if (statusObj.attackModeStartTime) {
        this.attackTimer = Math.round(
          (statusObj.attackModeStartTime +
            statusObj.attackModeDuration * 1000 -
            Date.now()) /
            1000
        );
        this.attackInterval = setInterval(() => {
          if (this.attackTimer > 0) {
            this.attackTimer--;
          } else {
            this.attackModeEnd();
          }
        }, 1000);
        this.attackModeOn = true;
      } else {
        this.attackModeOn = false;
        this.attackTimer = 0;
      }
      this.attacksDone = statusObj.attackModeCounter;
      this.attacksDoneString = `${this.attacksDone}/2`;
    });
    this.sio.server.on(
      "attackModeStart",
      (attackObj: {
        attackModeStartTime: number;
        attackModeDuration: number;
      }) => {
        this.attackModeBegin(
          attackObj.attackModeStartTime,
          attackObj.attackModeDuration
        );
      }
    );
    this.sio.server.on("attackModeEnd", () => {
      this.attackModeEnd();
    });
    this.sio.server.on(
      "attackSet",
      (attackState: {
        attackSet: boolean;
        followAttack: boolean;
        attackOpposite: boolean;
      }) => {
        this.attackModeSet = attackState.attackSet;
        this.followAttack = attackState.followAttack;
        this.attackOpposite = attackState.attackOpposite;
      }
    );
    this.sio.server.on("green", (code) => {
      this.setGreenCode(code);
    });

    this.sio.server.on("orange", (code) => {
      this.setOrangeCode(code);
    });

    this.sio.server.on("position", (pos: number) => {
      if (pos === null || pos === undefined) return;
      this.position = pos;
      this.checkIfInsideOvertakingSegment();
      this.checkIfInsideGhostSegment();
    });

    this.sio.server.on("driverEnergy", (energy: number) => {
      this.energy = energy;
      this.energyString = this.energy.toFixed(2);
    });

    this.sio.server.on("driverSoc", (soc: number) => {
      this.soc = soc;
    });

    this.sio.server.on("targetEnergy", (energy: number) => {
      this.targetEnergy = energy;
      this.targetEnergyString = this.targetEnergy.toFixed(2);
      this.animateTargetEnergy = true;
      setTimeout(() => (this.animateTargetEnergy = false), 500);
    });
    this.sio.server.on("crashed", () => {
      if (this.crashed) return;
      this.crashed = true;
      this.animateCrash = true;
      setTimeout(() => (this.animateCrash = false), 200);
    });
    this.sio.server.on("lap", (lap: number) => {
      this.currentLap = lap;
    });
    this.sio.server.on(
      "pastLap",
      (pastLapData: { energyConsumed: number; energyTargetDelta: number }) => {
        this.prevLapEnergyConsumed = pastLapData.energyConsumed;
        this.prevLapEnergyTargetDelta = pastLapData.energyTargetDelta;
      }
    );
    this.sio.server.on("predictedConsumption", (energy: number) => {
      this.predictedConsumption = energy;
    });
    this.sio.server.on("nextOTSegmentUpdate", (energy: number) => {
      this.nextOTSegmentAEC = energy;
    });
    this.sio.server.on("gapFront", (data: { range: boolean; gap: string }) => {
      this.inRangeFront = data.range;
      this.gapFront = data.gap;
    });
    this.sio.server.on("gapBehind", (data: { range: boolean; gap: string }) => {
      this.inRangeBehind = data.range;
      this.gapBehind = data.gap;
    });
    this.sio.server.on("overtaking", (state: boolean) => {
      this.overtaking = state;
    });
    this.sio.server.on("beingOvertaken", (state: boolean) => {
      this.beingOvertaken = state;
    });
    this.sio.server.on(
      "specialOvertaking",
      (specialOvertakeState: {
        overtakingDisabled: boolean;
        allowOvertake: boolean;
      }) => {
        this.overtakingDisabled = specialOvertakeState.overtakingDisabled;
        this.allowOvertake = specialOvertakeState.allowOvertake;
      }
    );
    this.sio.server.on("settingSCPace", (state: boolean) => {
      this.settingSCPace = state;
    });
    this.sio.server.on("leaderSCPace", (pace: number) => {
      this.scPace = pace;
    });
    this.sio.server.on(
      "gapsAround",
      (data: Array<{ num: string; gap: number }> | null) => {
        this.gapsAround(data);
      }
    );
    this.sio.server.on(
      "trueDriver",
      (state: boolean) => (this.trueDriver = state)
    );
    this.sio.server.on(
      "defending",
      (state: boolean) => (this.defending = state)
    );
    this.sio.server.on("deploy", (state: boolean) => (this.deploy = state));
    this.sio.server.on("projectedOvertakingProbability", (prob: number) => {
      this.overtakingProbability = prob;
    });
    this.sio.server.on("driverUpdate", (data: Partial<this>) => {
      Object.assign(this, data);
    });
    this.sio.server.on("pitIn", (state: boolean) => {
      this.pitIn = state;
    });
    this.sio.server.on("hasCharged", (state: boolean) => {
      this.hasCharged = state;
    });
    this.sio.server.on("pitting", (state: boolean) => {
      this.pitting = state;
    });
  }

  sioRemoveEventListeners(): void {
    this.sio.server.off("driverStatus");
    this.sio.server.off("driverSelectionDenial");
    this.sio.server.off("driverKick");
    this.sio.server.off("attackModeStart");
    this.sio.server.off("attackModeEnd");
    this.sio.server.off("green");
    this.sio.server.off("orange");
    this.sio.server.off("position");
    this.sio.server.off("targetEnergy");
    this.sio.server.off("crashed");
    this.sio.server.off("lap");
    this.sio.server.off("driverUsersList");
    this.sio.server.off("nextOTSegmentUpdate");
    this.sio.server.off("gapFront");
    this.sio.server.off("gapBehind");
    this.sio.server.off("overtaking");
    this.sio.server.off("beingOvertaken");
    this.sio.server.off("settingSCPace");
    this.sio.server.off("leaderSCLapTime");
    this.sio.server.off("projectedOvertakingProbability");
    this.sio.server.off("driverUpdate");
    this.sio.server.off("pitIn");
    this.sio.server.off("hasCharged");
    this.sio.server.off("pitting");
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, `car${this.selectedDriver}`);
    this.sio.joinRoom(this.sio.server, "raceToolStatus");
    this.sio.joinRoom(this.sio.server, "driverUsers");
  }

  sioLeaveRooms(): void {
    this.sio.leaveRoom(this.sio.server, `car${this.selectedDriver}`);
    this.sio.leaveRoom(this.sio.server, "raceToolStatus");
    this.sio.leaveRoom(this.sio.server, "driverUsers");
  }

  subEvents(): void {
    this.events["statusUpdate"] = this.status.statusUpdateEvent.subscribe(
      (data: Status) => {
        this.updateRaceToolStatus(data);
      }
    );
    this.events["raceToolReset"] = this.status.raceToolResetEvent.subscribe(
      () => this.reset()
    );
    this.events["scroll"] = this.rc.scrollEvent.subscribe(() => {
      setTimeout(() => {
        const rcPanel = document.getElementById("rcMessages");
        if (rcPanel) {
          rcPanel.scrollTop = rcPanel.scrollHeight;
        }
      }, 100);
    });
    this.events["colour"] = this.colours.colourEvent.subscribe((obj) => {
      if (obj["colourUpdate"]) {
        this.getDrivers();
      }
    });
  }

  unsubEvents(): void {
    if (this.events["statusUpdate"] != undefined)
      this.events["statusUpdate"].unsubscribe();
    if (this.events["raceToolReset"] != undefined)
      this.events["raceToolReset"].unsubscribe();
    if (this.events["scroll"] != undefined) this.events["scroll"].unsubscribe();
    if (this.events["colour"] != undefined) this.events["colour"].unsubscribe();
  }

  defaultState(): void {
    this.energyOffset = 0;
    this.energyOffsetString = "0.00";
    this.aggressiveness = 0;
    this.lapOffset = 0;
    this.attackModeMode = "4+4";
    this.attackModeOn = false;
    this.attackModeSet = false;
    this.attackTimer = 0;
    this.attacksDone = 0;
    this.attacksDoneString = "0/2";
    this.greenCode = "";
    this.orangeCode = "";
    this.targetEnergy = 1;
    this.targetEnergyString = "1.000";
    this.crashed = false;
  }

  reset(): void {
    this.snackBar.open(`Race tool has been reset!`, "", { duration: 5000 });
    this.goBack();
  }

  getDrivers(): void {
    const tempArr = [];
    for (const i in this.colours.drivers) {
      const x = this.colours.drivers[i];
      x.num = i;
      tempArr.push(x);
    }
    tempArr.sort(function (a, b) {
      return order.indexOf(a.teamShortName) - order.indexOf(b.teamShortName);
    });
    this.drivers = tempArr;
  }

  updateRaceToolStatus(data: {
    currentFlag: string;
    initFinalLaps: number;
    attackZonePosition: number;
    overtakePositions: number[];
    preOvertakePositions: { pos: number; length: number }[];
    ghostSegmentPositions: { pos: number; length: number }[];
    attackScenarios: string[];
  }): void {
    const currFlag = data.currentFlag;
    const finalLaps = data.initFinalLaps;
    if (currFlag && flags.includes(currFlag)) {
      this.currentFlag = currFlag;
      this.flagColour = FLAG_COLOUR_MAP.get(currFlag);
      if (
        this.currentFlag === "SAFETY_CAR" ||
        this.currentFlag === "FULL_YELLOW"
      ) {
        this.overtakingForbiddenByFlag = true;
      } else {
        this.overtakingForbiddenByFlag = false;
      }
    }
    this.finalLaps = finalLaps;
    this.attackZonePosition = data.attackZonePosition;
    this.attackZoneTransformString = `${this.attackZonePosition * 100}%`;
    this.overtakePositions = data.overtakePositions;
    this.overtakePositionStrings = [];
    for (const pos of this.overtakePositions) {
      this.overtakePositionStrings.push(`${pos * 100}%`);
    }
    this.preOvertakePositions = data.preOvertakePositions;
    this.preOvertakePositionStrings = [];
    this.isInsideOTSegmentBools = {};
    this.lastPreOTSegment = 0;
    for (const otObj of this.preOvertakePositions) {
      this.preOvertakePositionStrings.push({
        leftCSS: `${otObj.pos * 100}%`,
        widthCSS: `${otObj.length * 100}%`,
      });
      this.isInsideOTSegmentBools[otObj.pos] = false;
      if (otObj.pos > this.lastPreOTSegment) {
        this.lastPreOTSegment = otObj.pos;
      }
    }
    this.ghostSegmentPositions = data.ghostSegmentPositions;
    this.ghostSegmentPositionStrings = [];
    this.isInsideGhostSegmentBools = {};
    this.lastGhostSegment = 0;
    for (const ghostObj of this.ghostSegmentPositions) {
      this.ghostSegmentPositionStrings.push({
        leftCSS: `${ghostObj.pos * 100}%`,
        widthCSS: `${ghostObj.length * 100}%`,
      });
      this.isInsideGhostSegmentBools[ghostObj.pos] = false;
      if (ghostObj.pos > this.lastGhostSegment) {
        this.lastGhostSegment = ghostObj.pos;
      }
    }
    this.possibleAttackModes = data.attackScenarios;
  }

  goBack(): void {
    this.router.navigateByUrl("/home");
  }

  goBackNewTab(event: MouseEvent): void {
    if (event.button === 1) {
      window.open("/home", "_blank");
    }
  }

  updateOffset(fromString = false): void {
    if (fromString) {
      if (
        isNaN(Number(this.energyOffsetString)) ||
        Number(this.energyOffsetString) > MAX_ENO ||
        Number(this.energyOffsetString) < MIN_ENO
      ) {
        this.snackBar.open("Invalid energy offset input.", "", {
          duration: 3000,
        });
        this.energyOffsetString = this.energyOffset.toFixed(2);
      } else {
        this.energyOffset = Number(this.energyOffsetString);
      }
    } else {
      this.energyOffset =
        Math.round((this.energyOffset + Number.EPSILON) * 1000) / 1000;
      this.energyOffsetString = this.energyOffset.toFixed(2);
    }
    this.sio.writeAPI("ENERGY_OFFSET", {
      driverNum: this.selectedDriver,
      energyOffset: this.energyOffset,
    });
  }

  increaseEnergyOffset(): void {
    if (this.energyOffset + 0.02 > MAX_ENO) return;
    if (this.energyOffset === -0.01) this.energyOffset += 0.01;
    else this.energyOffset += 0.02;
    this.updateOffset();
  }

  decreaseEnergyOffset(): void {
    if (this.energyOffset - 0.02 < MIN_ENO) return;
    if (this.energyOffset === 0.01) this.energyOffset -= 0.01;
    else this.energyOffset -= 0.02;
    this.updateOffset();
  }

  getOffsetColor(): string {
    if (this.energyOffset >= 0) {
      const r = 255 - this.energyOffset * 100 + 180;
      const g = 255 - this.energyOffset * 340;
      const b = g;
      return `rgb(${r}, ${g}, ${b})`;
    } else {
      const g = 255 + this.energyOffset * 100 + 180;
      const r = 255 + this.energyOffset * 340;
      const b = r;
      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  formatEnergyOffsetThumbLabel(energyOffset: number): string {
    return energyOffset.toFixed(2);
  }

  revertToAutoEnergy(): void {
    this.energyOffset = 0;
    this.updateOffset();
    this.sio.writeAPI("ENERGY_TARGET_RESET", {
      driverNum: this.selectedDriver,
    });
  }

  updateAggressiveness(): void {
    this.sio.writeAPI("AGGRESSION", {
      driverNum: this.selectedDriver,
      aggressiveness: this.aggressiveness,
    });
  }

  formatAggressivenessThumbLabel(agg: number): string {
    const aggLabels = { "-2": "<<", "-1": "<", "0": "-", "1": ">", "2": ">>" };
    return aggLabels[agg];
  }

  updateRegen(): void {
    this.sio.writeAPI("REGEN", {
      driverNum: this.selectedDriver,
      regen: this.regenPower,
    });
  }

  updateLapOffset(): void {
    this.lapOffset = Number(this.lapOffset);
    this.sio.writeAPI("LAP_OFFSET", {
      driverNum: this.selectedDriver,
      lapOffset: this.lapOffset,
    });
  }

  increaseLapOffset(): void {
    this.lapOffset = Number(this.lapOffset) + 1;
    this.updateLapOffset();
  }
  decreaseLapOffset(): void {
    this.lapOffset = Number(this.lapOffset) - 1;
    this.updateLapOffset();
  }

  attackModeBegin(startTime: number, duration: number): void {
    this.attackStartTime = startTime;
    this.attackTimer = duration;
    this.attackInterval = setInterval(() => {
      if (this.attackTimer > 0) {
        this.attackTimer--;
      }
    }, 1000);
    this.attackModeOn = true;
    this.attacksDone++;
    this.attacksDoneString = `${this.attacksDone}/2`;
  }

  attackModeEnd(): void {
    clearInterval(this.attackInterval);
    this.attackModeOn = false;
    this.attackModeSet = false;
  }

  setAttackMode(): void {
    if (this.attackModeSet) {
      this.sio.writeAPI("ATTACK_MODE_SET", { driverNum: this.selectedDriver });
    } else {
      this.sio.writeAPI("ATTACK_MODE_CANCEL", {
        driverNum: this.selectedDriver,
      });
    }
  }
  changeAttackMode(): void {
    const scenario =
      this.possibleAttackModes.findIndex(
        (s: string) => s === this.attackModeMode
      ) + 1;
    if (scenario < 1 || scenario > 3) {
      console.log("Invalid attack mode scenario!");
      return;
    }
    this.sio.writeAPI("ATTACK_MODE_SCENARIO", {
      driverNum: this.selectedDriver,
      scenario: scenario,
    });
  }

  setFollowAttack(): void {
    this.sio.writeAPI("FOLLOW_ATTACK", {
      driverNum: this.selectedDriver,
      state: this.followAttack,
    });
  }

  setAttackOpposite(): void {
    this.sio.writeAPI("ATTACK_OPPOSITE", {
      driverNum: this.selectedDriver,
      state: this.attackOpposite,
    });
  }

  setPitIn(): void {
    this.sio.writeAPI("PIT_IN", {
      driverNum: this.selectedDriver,
      state: this.pitIn,
    });
  }

  setNextOTSegmentAEC(): void {
    this.sio.writeAPI("LIFTOFF", {
      driverNum: this.selectedDriver,
      energy: this.nextOTSegmentAEC,
    });
  }

  resetNextOTSegmentAEC(): void {
    this.sio.writeAPI("RESET_LIFTOFF", { driverNum: this.selectedDriver });
  }

  setOvertakingDisabled(): void {
    this.sio.writeAPI("OVERTAKING_DISABLED", {
      driverNum: this.selectedDriver,
      state: this.overtakingDisabled,
    });
  }

  setAllowOvertake(): void {
    this.sio.writeAPI("ALLOW_OVERTAKE", {
      driverNum: this.selectedDriver,
      state: this.allowOvertake,
    });
  }

  setDefending(): void {
    this.sio.writeAPI("DEFEND", {
      driverNum: this.selectedDriver,
      state: this.defending,
    });
  }

  setDeploy(): void {
    this.sio.writeAPI("DEPLOY", {
      driverNum: this.selectedDriver,
      state: this.deploy,
    });
  }

  setSCPace(): void {
    this.sio.writeAPI("LEADER_SC_PACE", {
      driverNum: this.selectedDriver,
      pace: this.scPace,
    });
  }

  setGreenCode(greenCode: string): void {
    this.greenCode = greenCode;
    this.animateGreenCode();
  }

  setOrangeCode(orangeCode: string): void {
    this.orangeCode = orangeCode;
    this.animateOrangeCode();
  }

  animateGreenCode(): void {
    this.animateGreen = true;
    setTimeout(() => {
      this.animateGreen = false;
    }, 1500);
  }

  animateOrangeCode(): void {
    this.animateOrange = true;
    setTimeout(() => {
      this.animateOrange = false;
    }, 1500);
  }

  setAutoSendCodes(): void {
    this.sio.writeAPI("AUTO_CODES", {
      driverNum: this.selectedDriver,
      autoSendCodes: this.autoSendCodes,
    });
  }

  onKeydown(event: KeyboardEvent): boolean {
    if (event.key !== "Enter") return;
    event.preventDefault();
    return false;
  }

  echoKeyUp(event: KeyboardEvent): boolean {
    if (event.key !== "Enter") return;
    let msg = (<HTMLInputElement>document.activeElement).value.trim();
    if (event.shiftKey) {
      msg = `ENG: ${msg}`;
    }
    if (this.selectedDriver && msg) {
      this.emitMessage(this.selectedDriver, msg);
    }
    return false;
  }

  emitMessage(driverNum: string, msg: string): void {
    const timeoutCallback = (
      onSuccess: (response: { status: string }) => void,
      onTimeout: () => void,
      timeout: number
    ) => {
      let called = false;
      const timer = setTimeout(() => {
        if (called) return;
        called = true;
        onTimeout();
      }, timeout);
      return (...args: unknown[]) => {
        if (called) return;
        called = true;
        clearTimeout(timer);
        onSuccess.apply(this, args);
      };
    };
    this.sio.st.emit(
      "echoMessageRT",
      {
        num: driverNum,
        msg: msg,
        important: false,
      },
      timeoutCallback(
        (response: { status: string }) => {
          if (response.status === "ERROR") {
            this.snackBar.open(
              `Could not send echo message! Room might not be open yet.`,
              "Ok",
              { duration: 5000 }
            );
          } else {
            (<HTMLInputElement>(
              document.getElementById("echoMessageBox")
            )).value = "";
            this.setEchoCheck();
          }
        },
        () => {
          this.snackBar.open(
            "Error sending echo message! Try reloading the page.",
            "Ok",
            { duration: 5000 }
          );
          this.setEchoBorder("red");
        },
        500
      )
    );
  }

  setEchoBorder(color: string): void {
    if (this.echoErrorTimeout) clearTimeout(this.echoErrorTimeout);
    this.echoTextBoxBorder = `2px solid ${color}`;
    this.echoErrorTimeout = setTimeout(() => {
      this.echoTextBoxBorder = "2px solid black";
    }, 2000);
  }

  setEchoCheck(): void {
    if (this.echoCheckTimeout) clearTimeout(this.echoCheckTimeout);
    this.echoCheck = true;
    this.echoCheckTimeout = setTimeout(() => {
      this.echoCheck = false;
    }, 800);
  }

  gapsAround(data: Array<{ num: string; gap: number }> | null): void {
    if (!data) return;
    this.gaps = [];
    for (const gapObj of data) {
      const adjustedGap = gapObj.gap + GAP_RANGE;
      const gapFrac = adjustedGap / (GAP_RANGE * 2);
      this.gaps.push({
        num: gapObj.num,
        gap: gapObj.gap,
        fractionalGap: gapFrac,
      });
    }
  }

  isCarInsideOvertakingSegment(otCSS: {
    leftCSS: string;
    widthCSS: string;
  }): boolean {
    const segmentStartPos = Number(otCSS.leftCSS.slice(0, -1)) / 100;
    const segmentWidth = Number(otCSS.widthCSS.slice(0, -1)) / 100;
    const segmentEndPos = segmentStartPos + segmentWidth;
    let result =
      this.position >= segmentStartPos && this.position <= segmentEndPos;
    this.isInsideOTSegmentBools[segmentStartPos] = result;
    if (!result) {
      if (segmentEndPos >= 0.999) {
        result = this.isInsideOTSegmentBools["0"];
      } else if (segmentStartPos == 0) {
        result = this.isInsideOTSegmentBools[this.lastPreOTSegment];
      }
    }
    return result;
  }

  isCarInsideGhostSegment(ghostCSS: {
    leftCSS: string;
    widthCSS: string;
  }): boolean {
    const segmentStartPos = Number(ghostCSS.leftCSS.slice(0, -1)) / 100;
    const segmentWidth = Number(ghostCSS.widthCSS.slice(0, -1)) / 100;
    const segmentEndPos = segmentStartPos + segmentWidth;
    let result =
      this.position >= segmentStartPos && this.position <= segmentEndPos;
    this.isInsideGhostSegmentBools[segmentStartPos] = result;
    if (!result) {
      if (segmentEndPos >= 0.999) {
        result = this.isInsideGhostSegmentBools["0"];
      } else if (segmentStartPos == 0) {
        result = this.isInsideGhostSegmentBools[this.lastGhostSegment];
      }
    }
    return result;
  }

  checkIfInsideOvertakingSegment(): void {
    let isInsideAny = false;
    for (const styling of this.preOvertakePositionStrings) {
      const inside = this.isCarInsideOvertakingSegment(styling);
      this.otSegmentColours[styling.leftCSS] = inside ? "#ff5055" : "#ffc0cc";
      if (inside) isInsideAny = true;
    }
    this.isInsideAnyOTSegment = isInsideAny;
  }

  checkIfInsideGhostSegment(): void {
    for (const styling of this.ghostSegmentPositionStrings) {
      const inside = this.isCarInsideGhostSegment(styling);
      this.ghostSegmentColours[styling.leftCSS] = inside
        ? "#ffa500"
        : "#daa520";
    }
  }

  get goingForOvertake(): boolean {
    return (
      this.isInsideAnyOTSegment &&
      !this.overtakingForbidden &&
      !this.overtakingForbiddenByFlag &&
      !this.overtakingDisabled &&
      this.inRangeFront
    );
  }
}
