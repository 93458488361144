import { Injectable } from "@angular/core";
import { SocketioService } from "../socketio/socketio.service";
import { FunctionsService } from "../functions/functions.service";
import axios from "axios";
import { urls } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EnergiesService {
  constructor(
    private sio: SocketioService,
    private auth: AuthService,
    private func: FunctionsService
  ) {
    this.sioEventListeners();
    this.sioJoinRooms();
    axios
      .get(`${urls["server"]}api/racetool/get-energies`, this.auth.apiConfig)
      .then(
        (resp: {
          data: {
            name: string;
            num: string;
            pos: number;
            energy: number;
            colour: string;
          }[];
        }) => {
          this.energies = resp.data;
          return null;
        }
      )
      .catch(() => {
        /**/
      });
  }

  private liveUpdatingStateSubject = new Subject<boolean>();
  liveUpdatingStateEvent = this.liveUpdatingStateSubject.asObservable();

  private energiesSubject = new Subject<
    {
      name: string;
      num: string;
      pos: number;
      energy: number;
      colour: string;
    }[]
  >();
  energiesEvent = this.energiesSubject.asObservable();

  energies: {
    name: string;
    num: string;
    pos: number;
    energy: number;
    colour: string;
  }[] = [];
  liveUpdatingEnergy = false;
  attacks = {};

  sioEventListeners(): void {
    this.sio.server.on(
      "energiesUpdate",
      (
        energies: {
          name: string;
          num: string;
          pos: number;
          energy: number;
          colour: string;
        }[]
      ) => {
        this.energies = energies;
        this.energiesSubject.next(energies);
      }
    );
    this.sio.server.on("liveUpdatingEnergyState", (state: boolean) => {
      this.liveUpdatingEnergy = state;
      this.liveUpdatingStateSubject.next(state);
    });
    this.sio.server.on("attackUpdate", (data: unknown) => {
      this.func.merge(this.attacks, data);
    });
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "energies");
    this.sio.joinRoom(this.sio.server, "attacks");
  }

  resetSio(): void {
    this.sio.server.off("energiesUpdate");
    this.sio.server.off("liveUpdatingEnergyState");
    this.sio.leaveRoom(this.sio.server, "energies");

    this.sioEventListeners();
    this.sioJoinRooms();
  }
}
