import { Component, OnInit } from "@angular/core";
import { EnergiesService } from "../../../services/energies/energies.service";
import { SessionStatusService } from "../../../services/session-status/session-status.service";

console.log("energyServ", EnergiesService);
@Component({
  selector: "app-energies-table",
  templateUrl: "./energies-table.component.html",
  styleUrls: ["./energies-table.component.scss"],
})
export class EnergiesTableComponent implements OnInit {
  constructor(
    public energies: EnergiesService,
    public status: SessionStatusService
  ) {}

  ngOnInit(): void {
    this.energies.resetSio();
  }
}
