import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FunctionsService {
  constructor() {}
  clockFormat(time: number | null | undefined, decimal = false) {
    if (time == null || time == undefined) {
      return null;
    } else {
      var sign = "";
      if (time < 0) {
        sign = "-";
      }
      let distrTime = Math.abs(time);
      let mins = Math.floor(distrTime / 60);
      distrTime -= mins * 60;
      let secs;
      let ms;
      if (decimal) {
        secs = Math.floor(distrTime);
        ms = Math.round((distrTime - secs) * 1000);
      } else {
        secs = Math.round(distrTime);
      }
      let minStr = mins.toString();
      if (minStr.length == 1) {
        minStr = "0" + mins;
      }
      let secStr = secs.toString();
      if (secStr.length == 1) {
        secStr = "0" + secs;
      }
      let clock = sign;
      clock += minStr;
      clock += ":";
      clock += secStr;
      if (decimal && minStr == "00" && secStr == "00") {
        clock += ".";
        clock += ms;
      }
      return clock;
    }
  }

  round(num: any, precision: any) {
    if (num) {
      // return parseFloat(num.toFixed(precision));
      return +(
        Math.round(parseFloat(`${num}e+${precision}`)) + `e-${precision}`
      );
    } else {
      return num;
    }
  }

  merge(a: { [x: string]: any } | null, b: { [x: string]: any } | null) {
    if (b == null) {
      a = null;
    } else {
      for (var key in b) {
        if (a == null) {
          a = {};
          a[key] = b[key];
        } else if (key in a) {
          if (b[key] == null) {
            a[key] = null;
          }
          if (
            typeof a[key] == "object" &&
            typeof b[key] == "object" &&
            !Array.isArray(a[key]) &&
            !Array.isArray(b[key])
          ) {
            a[key] = this.merge(a[key], b[key]);
          } else if (a[key] !== b[key]) {
            a[key] = b[key];
          }
        } else {
          a[key] = b[key];
        }
      }
    }
    return a;
  }

  randomSort(array: any[]) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  weightedRandomSort(arr: any[], weights: { [x: string]: number }) {
    if (arr.length == 1) return arr;
    let totalWeight = 0;
    for (let wgt of Object.values(weights)) {
      totalWeight += wgt;
    }
    let cumWeight = 0;
    let cumWeights = {};
    for (let item of arr) {
      cumWeight += weights[item];
      cumWeights[item] = cumWeight;
    }
    let rng = Math.random() * totalWeight;
    let nextItem = arr[0];
    for (let item of arr) {
      if (rng < cumWeights[item]) {
        nextItem = item;
        break;
      }
    }
    let nextArr = arr.filter((item) => item != nextItem);
    let nextWeights = JSON.parse(JSON.stringify(weights));
    delete nextWeights[nextItem];
    return [nextItem].concat(this.weightedRandomSort(nextArr, nextWeights));
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
