<app-main-nav>
  <div id="all" class="fadeIn">
    <div id="homeBar">
      <button
        mat-raised-button
        id="homeButton"
        (click)="home()"
        (auxclick)="homeNewTab($event)"
      >
        <mat-icon>home</mat-icon>
      </button>
    </div>
    <div id="main">
      <div id="centerBox">
        <div id="sessionStatus"><app-session-status></app-session-status></div>
        <div id="energiesPanel">
          <app-energies-table></app-energies-table>
          <div id="tvEnergiesButtonContainer">
            <button
              mat-raised-button
              id="tvEnergiesButton"
              (click)="toggleWindow()"
              class="jaguarFont"
            >
              <mat-icon> open_in_browser </mat-icon>
            </button>
          </div>
        </div>
        <div id="racePlotPanel">
          <div id="racePlot">
            <app-race-plot></app-race-plot>
          </div>
          <div id="racePlotPopoutButtonContainer">
            <button
              mat-raised-button
              id="racePlotPopoutButton"
              (click)="toggleRacePlotWindow()"
              class="jaguarFont"
            >
              <mat-icon> open_in_browser </mat-icon>
            </button>
          </div>
        </div>
        <div id="standingsPanel"></div>
        <div id="mapPanel"></div>
      </div>
    </div>
  </div>
</app-main-nav>
