/* eslint-disable sonarjs/no-duplicate-string */
import { Component, OnInit, Injectable } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../services/auth/auth.service";
import { SocketioService } from "../../services/socketio/socketio.service";
import { FunctionsService } from "../../services/functions/functions.service";
import { ConfigsService } from "../../services/configs/configs.service";
import { Router } from "@angular/router";
import axios from "axios";
import { urls } from "../../../environments/environment";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class GridService {
  constructor(
    private sio: SocketioService,
    public auth: AuthService,
    private configs: ConfigsService
  ) {
    this.sioEventListeners();
    this.sioJoinRooms();
  }

  private startingGridSubject = new Subject<{ [key: string]: number }>();
  startingGridEvent = this.startingGridSubject.asObservable();

  private currParamsSubject = new Subject<{ [key: string]: unknown }>();
  currParamsEvent = this.currParamsSubject.asObservable();

  startingGrid: { [key: string]: number } = {};
  currParams: { [key: string]: unknown } = {};

  sioEventListeners(): void {
    this.sio.server.on("startingGrid", (grid: { [key: string]: number }) => {
      this.startingGrid = grid;
      this.startingGridSubject.next();
    });
    this.sio.server.on("currParams", (params: { [key: string]: unknown }) => {
      this.currParams = params;
      this.currParamsSubject.next();
    });
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "grid");
    this.sio.joinRoom(this.sio.server, "import");
  }

  setDriverPos(driverNum: string, pos: number): void {
    this.startingGrid[driverNum] = pos;
  }

  getDriverPos(driverNum: number): number {
    return this.startingGrid[driverNum];
  }

  loadImport(name: string): void {
    const id = this.configs.configMap[name];
    axios
      .get(urls["server"] + "api/race-import/" + id, this.auth.apiConfig)
      .then((resp) => {
        this.startingGrid = {};
        let pos = 1;
        for (const driverObj of resp.data.data.params.grid) {
          this.startingGrid[driverObj.num] = pos;
          pos++;
        }
        this.startingGridSubject.next();
        this.currParams = resp.data.data;
        this.currParams["name"] = resp.data["name"];
        this.currParamsSubject.next();
        return true;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  ngOnDestroy(): void {
    this.sio.server.off("startingGrid");
    this.sio.leaveRoom(this.sio.server, "grid");
  }
}

@Component({
  selector: "app-import-page",
  templateUrl: "./import-page.component.html",
  styleUrls: ["./import-page.component.scss"],
  providers: [GridService],
})
export class ImportPageComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public grid: GridService,
    private clipboard: Clipboard,
    private snackbar: MatSnackBar,
    private router: Router,
    private sio: SocketioService,
    private func: FunctionsService,
    public configs: ConfigsService,
    public dialog: MatDialog
  ) {}

  drivers: Array<{
    num: string;
    name: string;
    team: string;
    frontierFactor: number;
    autoCodes: boolean;
    trueDriver: boolean;
  }>;

  events = {};
  gridArr = [];
  gridString = "";
  gridMessage = "";
  importName = "";
  strategyId = "";

  importParams = [];
  paramNameToIdx = new Map();

  importOutput = {};
  importOutputString = "";

  generated = false;

  chosenConfig: string;
  availableConfigs: Array<string>;

  showMap = false;

  ngOnInit(): void {
    this.subEvents();
    this.chosenConfig = this.configs.chosenConfig;
  }

  subEvents(): void {
    this.events["currParams"] = this.grid.currParamsEvent.subscribe(
      async () => {
        const resp = await axios.get(
          urls["server"] + "api/racetool/get-import-params",
          this.auth.apiConfig
        );
        this.importParams = [];
        let idxCounter = 0;
        for (const [paramName, paramValue] of Object.entries(
          resp.data["params"]
        )) {
          if (typeof paramValue == "object") {
            this.importParams.push({
              name: paramName,
              type: paramValue["type"],
              value: null,
              description: paramValue["description"],
            });
            this.paramNameToIdx.set(paramName, idxCounter);
            idxCounter++;
          }
        }
        this.strategyId = this.grid.currParams["imports"]["strategy"];
        this.importName = this.grid.currParams["name"] as string;
        this.drivers = [];
        for (const [paramName, paramValue] of Object.entries(
          this.grid.currParams["params"]
        )) {
          const idx = this.paramNameToIdx.get(paramName);
          if (
            idx != undefined &&
            (typeof paramValue == "string" ||
              typeof paramValue == "number" ||
              typeof paramValue == "boolean" ||
              Array.isArray(paramValue))
          ) {
            this.importParams[idx]["value"] = paramValue;
          } else if (paramName == "grid") {
            this.gridSetup(paramValue);
          }
        }
        axios
          .get(urls["server"] + "api/racetool/get-drivers", this.auth.apiConfig)
          .then(async (resp) => {
            for (const carNum of Object.keys(resp.data)) {
              const driversIdx = this.grid.getDriverPos(Number(carNum)) - 1;
              this.drivers[driversIdx].team = resp.data[carNum]["team"];
              this.drivers[driversIdx].name = resp.data[carNum]["name"];
            }
            return true;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    );
  }

  unsubEvents(): void {
    if (this.events["currParams"] != undefined)
      this.events["currParams"].unsubscribe();
  }

  gridSetup(
    grid: { num: string; name: string; team: string; frontierFactor: number }[]
  ): void {
    for (const driverObj of grid) {
      const driverGridObj = {
        num: driverObj["num"],
        name: driverObj["name"],
        team: driverObj["team"],
        frontierFactor: driverObj["frontierFactor"],
        autoCodes: Object.prototype.hasOwnProperty.call(
          this.grid.currParams["params"],
          "automaticCodesDrivers"
        )
          ? (this.grid.currParams["params"]["automaticCodesDrivers"].includes(
              driverObj["num"]
            ) as boolean)
          : false,
        trueDriver: Object.prototype.hasOwnProperty.call(
          this.grid.currParams["params"],
          "trueDrivers"
        )
          ? (this.grid.currParams["params"]["trueDrivers"].includes(
              driverObj["num"]
            ) as boolean)
          : false,
      };
      this.drivers.push(driverGridObj);
    }
  }

  randomizeGrid(): void {
    const driverOrderArr = Array.from(this.drivers, (x) => x.num);
    const randomOrder = this.func.randomSort(driverOrderArr);
    this.drivers.sort(
      (a, b) => randomOrder.indexOf(a.num) - randomOrder.indexOf(b.num)
    );
    // axios
    // .get(urls["server"] + "api/static/quali", this.auth.apiConfig)
    // .then(async (resp) => {
    //   const randomOrder = this.func.weightedRandomSort(
    //     driverOrderArr,
    //     resp.data
    //   );
    //   this.drivers.sort(
    //     (a, b) => randomOrder.indexOf(a.num) - randomOrder.indexOf(b.num)
    //   );
    //   return true;
    // })
    // .catch((err) => console.error(err));
  }

  toggleMap(): void {
    this.showMap = !this.showMap;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  generate(generatedZones: { start: number; stop: number }[] = []): void {
    this.gridArr = [];
    this.gridMessage = "";
    const trueDrivers = [];
    const autoDrivers = [];
    for (const driverObj of this.drivers) {
      const driverGridObj = {
        num: driverObj.num,
      };
      if (driverObj.frontierFactor != null) {
        driverGridObj["frontierFactor"] = driverObj.frontierFactor;
      }
      if (driverObj.trueDriver) trueDrivers.push(driverObj.num);
      if (driverObj.autoCodes) autoDrivers.push(driverObj.num);
      this.gridArr.push(driverGridObj);
      this.gridMessage += driverObj.num + ",";
    }
    this.gridMessage = this.gridMessage.slice(0, -1);
    this.gridString = JSON.stringify(this.gridArr);
    const params = { grid: this.gridArr };
    for (const paramObj of this.importParams) {
      if (paramObj.type == "array") {
        params[paramObj.name] = getArrayValue(paramObj.value);
      } else if (paramObj.type == "number") {
        params[paramObj.name] = Number(paramObj.value);
      } else {
        params[paramObj.name] = paramObj.value;
      }
    }
    if (generatedZones.length > 0) params["ghostZones"] = generatedZones;
    params["trueDrivers"] = trueDrivers;
    params["automaticCodesDrivers"] = autoDrivers;
    const output = {
      dt: new Date(),
      data: {
        imports: {
          strategy: this.strategyId,
        },
        params: params,
      },
      name: this.importName,
    };
    this.importOutput = output;
    this.importOutputString = JSON.stringify(output);
    this.generated = true;

    /**
     * Converts an input value to a properly formatted array.
     */
    function getArrayValue(val: unknown): unknown[] {
      let out = [];
      if (typeof val == "string") {
        out = val.split(",");
      } else if (typeof val == "number") {
        out = [val];
      } else if (typeof val == "object") {
        out = val as unknown[];
      }
      return out;
    }
  }

  publishImport(): void {
    axios
      .post(
        urls["server"] + "api/race-import/",
        this.importOutput,
        this.auth.apiConfig
      )
      .then(async (resp) => {
        this.generated = false;
        if (resp.status == 201) {
          this.snackbar.open("Import published!", "", { duration: 2000 });
        } else {
          this.snackbar.open("Error publishing import", "", { duration: 2000 });
        }
        return true;
      })
      .catch((err) => console.error(err));
  }

  copyGrid(): void {
    this.clipboard.copy(this.gridString);
    if (this.gridString) {
      this.snackbar.open("Copied to clipboard 😊", "", { duration: 2000 });
    }
  }

  copyGridMessage(): void {
    this.clipboard.copy(this.gridMessage);
    if (this.gridMessage) {
      this.snackbar.open("Copied to clipboard 😊", "", { duration: 2000 });
    }
  }

  copyImportOutput(): void {
    this.clipboard.copy(this.importOutputString);
    if (this.importOutputString) {
      this.snackbar.open("Copied to clipboard 😊", "", { duration: 2000 });
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.drivers, event.previousIndex, event.currentIndex);
  }

  home(): void {
    this.router.navigateByUrl("/race-manager");
  }

  homeNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/race-manager", "_blank");
  }

  ngOnDestroy(): void {
    this.unsubEvents();
  }
}
