import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-viewer-page",
  templateUrl: "./viewer-page.component.html",
  styleUrls: ["./viewer-page.component.scss"],
})
export class ViewerPageComponent implements OnInit {
  constructor(private router: Router) {}

  showWindow: boolean;

  ngOnInit(): void {
    this.showWindow = false;
  }

  home(): void {
    this.router.navigateByUrl("/home");
  }

  homeNewTab(event: MouseEvent): void {
    if (event.button !== 1) return;
    window.open("/home", "_blank");
  }

  toggleWindow(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/energies"])
    );
    window.open(url, "_blank", "width=300,height=600");
    // this.router.navigateByUrl('/energies');
    // this.showWindow = !this.showWindow;
  }

  toggleRacePlotWindow(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/raceplot"])
    );
    window.open(url, "_blank", "width=800,height=500");
  }
}
