<app-main-nav>
  <div
    id="main"
    class="fadeIn"
    style="
      background-image: url(./assets/images/samcar.png);
      background-position: 0% 35%;
      background-size: cover;
    "
  >
    <button
      mat-raised-button
      id="homeButton"
      (click)="home()"
      (auxclick)="homeNewTab($event)"
    >
      <mat-icon>undo</mat-icon>
    </button>
    <div id="centerBox">
      <div id="sessionsPanel">
        <div id="sessionsTitle" class="jaguarFont panelTitle">
          Saved Sessions
        </div>
        <div id="sessionList" class="selectionList">
          <mat-selection-list
            [(ngModel)]="selectedSessionArr"
            [multiple]="false"
          >
            <mat-list-option
              id="sessionOption"
              *ngFor="let session of sessions"
              [value]="session"
              (click)="loadSession(session)"
            >
              {{ session.name }}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
      <div id="lapsPanel">
        <div id="lapsTitle" class="jaguarFont panelTitle">Laps</div>
        <div id="lapList" class="selectionList">
          <mat-selection-list [(ngModel)]="selectedLapsArr" [multiple]="false">
            <mat-list-option
              id="lapOption"
              *ngFor="let lap of laps"
              [value]="lap"
              (click)="loadLap(lap)"
            >
              {{ lap.lap }}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
      <div id="loadPanel">
        <div id="loadTitle" class="jaguarFont panelTitle">Saved State</div>
        <div id="standings">
          <div id="standingsTitle" class="jaguarFont panelTitle">Standings</div>
          <div
            id="standingsBody"
            class="jaguarFont"
            [style.border-color]="
              selectedLapsArr && selectedLapsArr[0].flag
                ? flagColour[selectedLapsArr[0].flag]
                : 'white'
            "
          >
            <div class="driverRow jaguarFont">
              <div class="driverRowPos">Pos</div>
              <div class="driverRowName">Driver</div>
              <div class="driverRowEnergy">Energy</div>
              <div class="driverRowAttacks">Attacks</div>
            </div>
            <div
              *ngFor="let driverObj of standings; let i = index"
              style="font-size: 0.6rem"
            >
              <div class="driverRow">
                <div class="driverRowPos" style="color: black">{{ i + 1 }}</div>
                <div
                  class="driverRowName"
                  [style.color]="
                    colours.getColourByDriverNum(driverObj.driverNum)
                  "
                >
                  {{ colours.getDriverNameByNum(driverObj.driverNum) }}
                </div>
                <div class="driverRowEnergy">
                  {{ driverObj.energy.toFixed(2) }}
                </div>
                <div
                  class="driverRowAttacks"
                  [style.color]="
                    driverObj.attackModeState.attackModeCounter == 2
                      ? 'darkgray'
                      : driverObj.attackModeState.attackMode
                      ? 'rgb(230, 100, 255)'
                      : 'white'
                  "
                >
                  {{ driverObj.attackModeState.attackModeCounter }} / 2
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="saveNameRow">
          <div class="jaguarFont" style="font-size: 0.8rem">
            Replay Session Name:
          </div>
          <form class="example-form">
            <mat-form-field appearance="none" id="sessionNameFormField">
              <input
                matInput
                id="saveNameInput"
                [(ngModel)]="newSessionName"
                name="newSessionName"
              />
            </mat-form-field>
          </form>
          <div style="visibility: hidden; font-size: 0.8rem" class="jaguarFont">
            Replay Session Name:
          </div>
        </div>
        <div id="loadButtonRow">
          <button
            mat-raised-button
            id="loadButton"
            class="jaguarFont"
            [disabled]="!selectedLapsArr"
            (click)="loadSaveState()"
          >
            Load Save State
          </button>
        </div>
        <div id="startButtonRow">
          <button
            mat-raised-button
            id="startButton"
            class="jaguarFont"
            [disabled]="status.loading"
            (click)="startSaveState()"
          >
            Start Save State
          </button>
        </div>
      </div>
    </div>
  </div>
</app-main-nav>
