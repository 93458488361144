import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { AdminGuard } from "./services/guard/admin.guard";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { DriverPageComponent } from "./pages/driver-page/driver-page.component";
import { RaceManagerPageComponent } from "./pages/race-manager-page/race-manager-page.component";
import { ImportPageComponent } from "./pages/import-page/import-page.component";
import { LoadSessionPageComponent } from "./pages/load-session-page/load-session-page.component";
import { ViewerPageComponent } from "./pages/viewer-page/viewer-page.component";
import { EnergiesPageComponent } from "./pages/individual-viewer-component-pages/energies-page/energies-page.component";
import { RaceplotPageComponent } from "./pages/individual-viewer-component-pages/raceplot-page/raceplot-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { TestPageComponent } from "./pages/test-page/test-page.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomePageComponent, canActivate: [AdminGuard] },
  { path: "driver", component: DriverPageComponent, canActivate: [AdminGuard] },
  {
    path: "race-manager",
    component: RaceManagerPageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "import-generator",
    component: ImportPageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "load-session",
    component: LoadSessionPageComponent,
    canActivate: [AdminGuard],
  },
  { path: "viewer", component: ViewerPageComponent, canActivate: [AdminGuard] },
  { path: "login", component: LoginPageComponent },
  {
    path: "energies",
    component: EnergiesPageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "raceplot",
    component: RaceplotPageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "supersecret",
    component: TestPageComponent,
    canActivate: [AdminGuard],
  },
  { path: "404", component: NotFoundPageComponent },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
