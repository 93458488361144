import { Injectable } from "@angular/core";
import io, { Socket } from "socket.io-client";
import { AuthService } from "../../services/auth/auth.service";
import { urls } from "../../../environments/environment";

type CustomSocket = Socket & {
  activeRooms: string[];
};

@Injectable({
  providedIn: "root",
})
export class SocketioService {
  server: CustomSocket;
  st: CustomSocket;

  nspConnectedCount = 0;
  connected = false;
  busy = false;

  constructor(private auth: AuthService) {
    this.sioConnect();
  }

  initConnections(sio: CustomSocket): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const namespace = sio.nsp;
    if (namespace == "/server") {
      sio.activeRooms = ["clock", "alerts"];
    } else {
      sio.activeRooms = [];
    }
    sio.on("joinAck", (room: string) => {
      console.log(`Joined socket.io ${namespace} room ${room}`);
    });
    sio.on("leaveAck", (room: string) => {
      console.log(`Left socket.io ${namespace} room ${room}`);
    });
    sio.on("connect", () => {
      console.log(`Connected to socket.io namespace ${namespace}`);
      this.nspConnectedCount += 1;
      this.updateAllConnected();
      for (const room of sio.activeRooms) {
        sio.emit("join", room);
      }
    });
    sio.on("disconnect", (reason: string) => {
      this.nspConnectedCount -= 1;
      this.updateAllConnected();
      console.log(
        `Disconnected from socket.io namespace ${namespace} because of ${reason}`
      );
    });
    sio.on("connect_error", (err: Error) => {
      console.log(
        `Error connecting to socket.io namespace ${namespace} - Error message: ${err.message}`
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (sio.io.skipReconnect) {
        console.log(
          "Socket.io is not handling the reconnection, you will have to refresh"
        );
      }
    });
  }

  gracefullyKill(sio: {
    disconnect: () => void;
    off: (arg0: string) => void;
  }): void {
    sio.disconnect();
    sio.off("joinAck");
    sio.off("leaveAck");
    sio.off("connect");
    sio.off("disconnect");
    sio.off("error");
    sio.off("connect_error");
  }

  sioConnect(): void {
    this.server = io(
      urls["server"] + "server",
      this.auth.wsConfig
    ) as CustomSocket;
    this.initConnections(this.server);
    this.st = io(urls["st"] + "server", this.auth.stWSConfig) as CustomSocket;
    this.initConnections(this.st);
    this.busy = false;
  }

  joinRoom(
    sio: {
      activeRooms: string[];
      connected: boolean;
      emit: (arg0: string, arg1: string) => void;
    },
    room: string
  ): void {
    sio.activeRooms.push(room);
    if (sio.connected) {
      sio.emit("join", room);
    }
  }

  leaveRoom(
    sio: { activeRooms: string[]; emit: (arg0: string, arg1: string) => void },
    room: string
  ): void {
    const index = sio.activeRooms.indexOf(room);
    if (index > -1) {
      sio.activeRooms.splice(index, 1);
    }
    sio.emit("leave", room);
  }

  updateAllConnected(): void {
    if (this.nspConnectedCount == 1) {
      this.connected = true;
    } else {
      this.connected = false;
    }
  }

  writeAPI(type: string, data: unknown): void {
    this.server.emit("api", {
      type: type,
      dt: Date.now(),
      data: data,
    });
  }
}
