<div class="jaguarFont" id="main">
  <h1>Greetings!</h1>
  <br />
  The purpose of this page is to test components and other random features.
  <br />
  If you are not a developer of the RaceTool and you arrived here, something has
  gone wrong.
  <br />
  Click <a [routerLink]="'/home'">here</a> to go back.
</div>
<app-map #map></app-map>
<button (click)="map.generateZones()">Generate</button>
{{ map.generatedZones[0]?.start }}
