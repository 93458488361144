/* eslint-disable sonarjs/cognitive-complexity */
import { Component, OnInit, Input } from "@angular/core";
import { RacePlotService } from "../../../services/race-plot/race-plot.service";
import { SessionStatusService } from "../../../services/session-status/session-status.service";
import { AuthService } from "../../../services/auth/auth.service";
import { FunctionsService } from "../../../services/functions/functions.service";
import { SocketioService } from "../../../services/socketio/socketio.service";
import { Chart } from "angular-highcharts";
import { ColoursService } from "../../../services/colours/colours.service";
import Highcharts, { DashStyleValue } from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);
import { cloneDeep } from "lodash";

const Y_MIN_FLOOR = -30;
const DEFAULT_Y_MIN = -10;

const Y_MAX_CEILING = 30;
const DEFAULT_Y_MAX = 0.25;

const MAX_X_RANGE_MARGIN = 4;

@Component({
  selector: "app-race-plot",
  templateUrl: "./race-plot.component.html",
  styleUrls: ["./race-plot.component.scss"],
})
export class RacePlotComponent implements OnInit {
  @Input() activeDriver: string;
  constructor(
    public rps: RacePlotService,
    private status: SessionStatusService,
    private auth: AuthService,
    private func: FunctionsService,
    private sio: SocketioService,
    private colours: ColoursService
  ) {}

  events = {};

  graphData = {};

  driverTimes = {};

  currentLap: number;
  numLaps: number;
  numLoops = 8;
  yMax = 0.25;
  yMin = -10;
  biggestNegativeGap = 0;
  biggestPositiveGap = 0;
  xMax: number;
  xMin = 0;
  loopIdxMap: Map<string, number>;
  startLoop = Infinity;
  startLap = Infinity;
  lap0 = false;
  startLinePosition = 0;

  timingLoops: { [key: string]: number } = {
    FL: 0,
    SCL2: 0.021,
    TV1: 0.24,
    IP1: 0.388,
    TV2: 0.581,
    IP2: 0.636,
    TV3: 0.728,
    SCL1: 0.882,
  };

  selectedRef = sessionStorage.getItem("selectedRef") || "Leader";
  refOpts = ["Leader", "Laptime"];

  lapTimeInput = sessionStorage.getItem("refLapTime") || "70";
  refLapTime = Number(this.lapTimeInput);

  flagTimes = [];
  flagIndicies = [];
  flagBands: { color: string; from: number; to: number }[] = [];
  intervention = false;
  interventionIndicies: Set<number> = new Set();

  yAxisTitle = "Gap To Leader (s)";
  xAxisData: string[] = [];
  customYAxis = false;

  zoomed = false;

  absoluteTimes: { [key: string]: number[] } = {};
  referenceTimes: number[] = [];
  leaderTimes: number[] = [];
  leaderDrivers: string[] = [];
  tailerTimes: number[] = [];

  attackIndices: { [key: string]: { attack: number[]; marker: number[] } } = {};

  biggestIdx = 0;

  lapLoopToIdx = (lap: number, loop: number): number =>
    (lap - 1) * 8 + (loop + 1) - 2;

  xRange = 20;

  chartRef: Highcharts.Chart;

  firstDrivers: Set<string> = new Set();

  attackHighlightOptions = [
    { num: "9", selected: false },
    { num: "37", selected: false },
    { num: "4", selected: false },
    { num: "16", selected: false },
  ];
  attackHighlightDrivers: string[] = [];
  plotLines: {
    [key: string]: {
      color: string;
      width: number;
      value: number;
      dashStyle?: DashStyleValue;
      zIndex?: number;
    };
  } = {};
  attackModeGap = 2.6;
  driverToColour = {
    "9": "red",
    "37": "blue",
    "4": "orange",
    "16": "green",
  };

  chart = new Chart({
    chart: {
      type: "line",
      backgroundColor: "rgb(225, 225, 225)",
      animation: false,
      zooming: {
        type: "xy",
        resetButton: {
          theme: {
            style: {
              display: "none",
            },
          },
        },
      },
      panKey: "shift",
      panning: {
        type: "xy",
        enabled: true,
      },
    },
    boost: {
      useGPUTranslations: true,
      seriesThreshold: 20,
    },
    title: { text: "Race Plot" },
    yAxis: {
      title: { text: "Gap (s)" },
      startOnTick: false,
      endOnTick: false,
      max: this.yMax,
      min: this.yMin,
    },
    time: {
      useUTC: false,
    },
    xAxis: {
      title: { text: "Lap" },
      type: "category",

      events: {
        setExtremes: () => {
          this.zoomed = true;
        },
      },
      categories: this.xAxisData,
      crosshair: {
        dashStyle: "LongDash",
        width: 1,
        color: "rgb(0,0,0)",
      },
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      range: this.xAxisData.length,
      labels: {
        step: this.numLoops,
        rotation: -30,
        formatter: function () {
          return String(this.value).split("-")[0].trim();
        },
        style: {
          color: "#000",
        },
      },
    },
    tooltip: {
      enabled: true,
      hideDelay: 0,
      snap: 8,
    },
    exporting: {
      enabled: true,
      sourceHeight: 1080,
      sourceWidth: 1920,
    },
    plotOptions: {
      series: {
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
        animation: false,
        stickyTracking: false,
      },
    },
    credits: { enabled: false },
    series: [],
    legend: {
      enabled: false,
    },
  });

  ngOnInit(): void {
    this.sioJoinRooms();
    this.sioEventHandlers();
    this.subEvents();
    this.resetPlot(this.status.lap0);
    this.updatePlot(this.rps.driverData);
  }

  ngOnDestroy(): void {
    this.sioLeaveRooms();
    this.sioOffEventListeners();
    this.unsubEvents();
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "raceplotTiming");
  }

  sioLeaveRooms(): void {
    this.sio.leaveRoom(this.sio.server, "raceplotTiming");
  }

  sioEventHandlers(): void {
    this.sio.server.on("timingLoops", (data: { [key: string]: number }) => {
      this.timingLoops = data;
      this.changeRef();
    });
  }

  sioOffEventListeners(): void {
    this.sio.server.off("timingLoops");
  }

  subEvents(): void {
    this.events["colour"] = this.colours.colourEvent.subscribe((obj) => {
      if (obj["colourUpdate"]) {
        this.updateColours(obj["colourUpdate"]);
      }
    });
    this.events["reset"] = this.status.raceToolResetEvent.subscribe(
      (data: { lap0: boolean; timingLoops: { [keys: string]: number } }) => {
        this.timingLoops = data.timingLoops;
        this.resetPlot(data.lap0);
      }
    );
    this.events["racePlotUpdate"] = this.rps.racePlotUpdateEvent.subscribe(
      (data) => this.updatePlot(data)
    );
    this.events["racePlotFlag"] = this.rps.racePlotFlagEvent.subscribe((data) =>
      this.processFlags(data)
    );
    this.events["config"] = this.rps.racePlotConfigEvent.subscribe((data) => {
      this.lap0 = data.lap0;
      this.startLinePosition = data.startLinePosition;
      this.attackModeGap = data.attackModeGap;
      this.resetPlot(this.lap0);
      this.updatePlot(this.rps.driverData);
      this.clearFlags();
      this.processFlags(this.rps.flagData);
    });
  }

  unsubEvents(): void {
    if (this.events["colour"] != undefined) this.events["colour"].unsubscribe();
    if (this.events["reset"] != undefined) this.events["reset"].unsubscribe();
    if (this.events["racePlotUpdate"] != undefined)
      this.events["racePlotUpdate"].unsubscribe();
    if (this.events["config"] != undefined) this.events["config"].unsubscribe();
  }

  resetPlot(lap0: boolean): void {
    this.lap0 = lap0;
    this.chart.ref$.subscribe((chart) => {
      while (chart.series.length > 0) {
        chart.series[0].remove(false);
      }
      this.chartRef = chart;
      this.xMax = this.numLoops;
      this.chartRef.xAxis[0].setExtremes(this.xMin, this.xMax);
      this.zoomed = false;
    });
    const teams = new Set();
    this.firstDrivers = new Set();
    for (const participant in this.colours.drivers) {
      const team = this.colours.getDriverTeamByNum(participant);
      const firstDriver = !teams.has(team);
      if (firstDriver) {
        teams.add(team);
        this.firstDrivers.add(participant);
      }
      const name = this.colours.getDriverNameByNum(participant);
      if (name.slice(0, 2) === "DR") continue;
      this.addDriverSeries(participant);
      this.absoluteTimes[participant] = [];
    }
    this.lapLoopToIdx = lap0
      ? (lap: number, loop: number): number => lap * 8 + (loop + 1) - 1
      : (lap: number, loop: number): number => (lap - 1) * 8 + (loop + 1) - 1;
    this.referenceTimes = [];
    this.leaderTimes = [];
    this.leaderDrivers = [];
    this.tailerTimes = [];
    this.xAxisData = [];
    this.attackIndices = {};
    this.yMax = this.selectedRef === "Leader" ? 0.25 : DEFAULT_Y_MAX;
    this.yMin = 0;
    this.biggestNegativeGap = 0;
    this.biggestPositiveGap = 0;
    this.biggestIdx = 0;
    this.numLaps = this.status.status.currentLap;
    this.currentLap = this.numLaps;
    this.zoomed = false;
    this.setXAxisData();
    if (this.selectedRef === "Laptime") this.generateRefTimes();
  }

  setXAxisData(): void {
    const startLap = this.lap0 ? 0 : 1;
    this.xAxisData[0] = this.lap0 ? "0-0" : "1-0";
    for (let i = startLap; i <= 100; i++) {
      for (let j = 0; j < this.numLoops; j++) {
        const idx = this.lapLoopToIdx(i, j);
        if (j === 7) {
          this.xAxisData[idx + 1] = `${i + 1}-FL`;
          continue;
        }
        const loopName = Object.keys(this.timingLoops)[j + 1];
        this.xAxisData[idx + 1] = `${i}-${loopName}`;
      }
    }
    this.chart.ref$.subscribe((chart) => {
      chart.update({ xAxis: { categories: this.xAxisData } });
    });
  }

  updatePlot(racePlotData: {
    [key: string]: {
      laps: {
        [key: string]: {
          loops: { [key: string]: { time: number; attackMode: boolean } };
        };
      };
    };
  }): void {
    for (const [driverNum, driverData] of Object.entries(racePlotData)) {
      let series = this.chart.ref.get(driverNum);
      if (!series) {
        this.addDriverSeries(driverNum);
        series = this.chart.ref.get(driverNum);
      }
      if (!(series instanceof Highcharts.Series)) continue;
      if (series.options.type !== "line") continue;
      const atkSeries = this.chartRef.get(driverNum + "-atk");
      const markerSeries = this.chartRef.get(driverNum + "-marker");
      if (
        !(atkSeries instanceof Highcharts.Series) ||
        !(markerSeries instanceof Highcharts.Series) ||
        atkSeries.options.type !== "line" ||
        markerSeries.options.type !== "line"
      )
        continue;
      let currData = cloneDeep(series.options.data) as number[];
      let currAtkData = cloneDeep(atkSeries.options.data) as number[];
      let currMarkerData = cloneDeep(markerSeries.options.data) as number[];
      this.mergeRacePlotDataToSeries(
        currData,
        currAtkData,
        currMarkerData,
        driverData,
        driverNum
      );
      currData = RacePlotComponent.nullifyEmpty(currData);
      currAtkData = RacePlotComponent.nullifyEmpty(currAtkData);
      currMarkerData = RacePlotComponent.nullifyEmpty(currMarkerData);
      series.update({ data: currData, type: "line" }, false);
      atkSeries.update({ data: currAtkData, type: "line" }, false);
      markerSeries.update({ data: currMarkerData, type: "line" }, false);
      const biggestLap = Math.max(
        ...Object.keys(driverData.laps).map((l) => Number(l))
      );
      if (biggestLap > this.currentLap) {
        this.currentLap = biggestLap;
      }
      if (this.attackHighlightDrivers.includes(driverNum)) {
        this.updateDriverAttackLines(driverNum);
      }
    }
    try {
      this.checkZoomOut();
    } catch {
      /* Sometimes can have one data message after reset message due to timer, and it's a harmless issue.
       */
    }
    try {
      this.chartRef.redraw();
    } catch {
      /* */
    }
  }

  updateDriverAttackLines(driverNum: string): void {
    if (!this.chartRef) return;
    const driverSeries = this.chartRef.get(driverNum);
    if (!driverSeries || !(driverSeries instanceof Highcharts.Series)) return;
    if (driverSeries.options.type !== "line") return;
    const driverData = driverSeries.options.data;
    const latestDatum = driverData[driverData.length - 1] as number;
    const attackLine = latestDatum - this.attackModeGap;
    this.plotLines[driverNum] = {
      color: this.driverToColour[driverNum],
      value: attackLine,
      width: 2,
      dashStyle: "LongDash",
      zIndex: 5,
    };
    this.updatePlotLines();
  }

  removeDriverAttackLines(driverNum: string): void {
    if (!this.chartRef) return;
    delete this.plotLines[driverNum];
    this.updatePlotLines();
  }

  updatePlotLines(): void {
    if (!this.chartRef) return;
    const plotLinesArr = [];
    for (const driverData of Object.values(this.plotLines)) {
      plotLinesArr.push(driverData);
    }
    this.chartRef.update({ yAxis: { plotLines: plotLinesArr } }, false);
  }

  checkZoomOut(): void {
    if (!this.zoomed) {
      this.numLaps = this.currentLap;
      this.xMax = this.numLoops * this.numLaps + MAX_X_RANGE_MARGIN;
      this.chartRef.xAxis[0].setExtremes(this.xMin, this.xMax);
      this.zoomed = false;
    }
    if (this.customYAxis) return;
    if (this.yMin >= Y_MIN_FLOOR && this.biggestNegativeGap < this.yMin) {
      this.yMin = Math.max(Y_MIN_FLOOR, Math.floor(this.biggestNegativeGap));
    }
    if (this.yMax <= Y_MAX_CEILING && this.biggestPositiveGap > this.yMax) {
      this.yMax = Math.min(Y_MAX_CEILING, Math.ceil(this.biggestPositiveGap));
    }
    if (!this.zoomed) {
      this.chartRef.yAxis[0].setExtremes(this.yMin, this.yMax);
      this.zoomed = false;
    }
  }

  mergeRacePlotDataToSeries(
    seriesData: number[],
    atkSeriesData: number[],
    markerSeriesData: number[],
    racePlotData: {
      laps: {
        [key: string]: {
          loops: { [key: string]: { time: number; attackMode: boolean } };
        };
      };
    },
    driverNum: string
  ): void {
    if (!this.attackIndices[driverNum])
      this.attackIndices[driverNum] = { attack: [], marker: [] };
    for (const [lap, lapData] of Object.entries(racePlotData.laps)) {
      for (const [loop, loopData] of Object.entries(lapData.loops)) {
        const idx = this.lapLoopToIdx(parseInt(lap), parseInt(loop));
        if (idx > this.biggestIdx) {
          this.biggestIdx = idx;
          if (this.intervention) {
            this.extendLatestFlag(this.biggestIdx);
            if (this.biggestIdx > 2)
              this.updateReferenceTime(this.biggestIdx, loopData.time);
          }
        }
        this.absoluteTimes[driverNum][idx] = loopData.time;
        const deltaSec =
          Math.round(
            this.calculateDeltaTime(idx, loopData.time, driverNum) * 1000
          ) / 1000;
        seriesData[idx] = deltaSec;
        if (deltaSec < this.biggestNegativeGap) {
          this.biggestNegativeGap = deltaSec;
        }
        if (deltaSec > this.biggestPositiveGap) {
          this.biggestPositiveGap = deltaSec;
        }
        if (!loopData.attackMode) {
          atkSeriesData[idx] = null;
          markerSeriesData[idx] = null;
          continue;
        }

        atkSeriesData[idx] = deltaSec;
        this.attackIndices[driverNum].attack.push(idx);
        if (atkSeriesData[idx - 1] !== null) {
          markerSeriesData[idx] = null;
          continue;
        }
        if (idx !== 0) {
          markerSeriesData[idx - 1] = seriesData[idx - 1];
          markerSeriesData[idx] = null;
          this.attackIndices[driverNum].marker.push(idx - 1);
          atkSeriesData[idx - 1] = seriesData[idx - 1];
          this.attackIndices[driverNum].attack.push(idx - 1);
        } else {
          markerSeriesData[idx] = deltaSec;
          this.attackIndices[driverNum].marker.push(idx);
        }
      }
    }
  }

  calculateDeltaTime(idx: number, time: number, driverNum: string): number {
    if (this.leaderTimes[idx] === undefined || this.leaderTimes[idx] > time) {
      this.leaderTimes[idx] = time;
      this.leaderDrivers[idx] = driverNum;
      if (this.selectedRef === "Leader") this.updateLoopTimes(idx);
      if (this.selectedRef === "Laptime" && idx > 0) {
        if (this.isIndexIntervention(idx)) {
          this.updateReferenceTime(idx);
        } else {
          this.updateLoopTimesReference(idx, this.referenceTimes);
        }
      }
    }

    if (this.tailerTimes[idx] === undefined || this.tailerTimes[idx] < time) {
      this.tailerTimes[idx] = time;
    }
    if (this.selectedRef === "Laptime") {
      return this.referenceTimes[idx] - time / 1000;
    }
    return (this.leaderTimes[idx] - time) / 1000;
  }

  updateLoopTimes(idx: number): void {
    for (const series of this.chart.ref.series) {
      if (series.options.type !== "line") continue;
      const seriesData = series.options.data as number[];
      let driverNum = series.options.id as string;
      let isAttackSeries = false;
      let isMarkerSeries = false;
      if (driverNum.length > 2) {
        const otherTag = driverNum.split("-")[1];
        driverNum = driverNum.split("-")[0];
        if (otherTag === "atk") {
          isAttackSeries = true;
        } else {
          isMarkerSeries = true;
        }
      }
      const absoluteLoopTime = this.absoluteTimes[driverNum][idx];
      if (absoluteLoopTime === undefined) continue;
      const relativeLoopTime = this.leaderTimes[idx] - absoluteLoopTime;
      const relativeLoopTimeSec = Math.round(relativeLoopTime) / 1000;
      if (
        isAttackSeries &&
        (!this.attackIndices[driverNum] ||
          !this.attackIndices[driverNum].attack.includes(idx))
      ) {
        continue;
      }
      if (
        isMarkerSeries &&
        (!this.attackIndices[driverNum] ||
          !this.attackIndices[driverNum].marker.includes(idx))
      )
        continue;
      seriesData[idx] = relativeLoopTimeSec;
      if (relativeLoopTimeSec < this.biggestNegativeGap) {
        this.biggestNegativeGap = relativeLoopTimeSec;
      }
      if (relativeLoopTimeSec > this.biggestPositiveGap) {
        this.biggestPositiveGap = relativeLoopTimeSec;
      }
    }
  }

  addDriverSeries(driverNum: string): void {
    const name = this.colours.getDriverNameByNum(driverNum);
    this.chart.addSeries(
      {
        name: name,
        data: [],
        color: this.colours.getColourByDriverNum(driverNum),
        marker: { enabled: false },
        animation: false,
        lineWidth: 3,
        dashStyle: this.firstDrivers.has(driverNum) ? "Solid" : "ShortDot",
        type: "line",
        visible: true,
        boostThreshold: 20,
        id: driverNum,
        zIndex: 100,
      },
      false,
      false
    );
    this.chart.addSeries(
      {
        name: name + "atk",
        data: [],
        color: this.colours.getColourByDriverNum(driverNum),
        opacity: 0.4,
        type: "line",
        marker: {
          enabled: false,
        },
        lineWidth: 8,
        id: driverNum + "-atk",
        showInLegend: false,
        enableMouseTracking: false,
        skipKeyboardNavigation: true,
        zIndex: 0,
      },
      false,
      false
    );
    this.chart.addSeries(
      {
        name: name + "marker",
        data: [],
        color: "rgb(255,255,255)",
        type: "line",
        marker: {
          symbol: "circle",
          enabled: true,
          lineColor: "rgb(0,0,0)",
          lineWidth: 1,
        },
        id: driverNum + "-marker",
        showInLegend: false,
        enableMouseTracking: false,
        skipKeyboardNavigation: true,
        zIndex: 101,
      },
      false,
      false
    );
    this.absoluteTimes[driverNum] = [];
  }

  changeRef(): void {
    sessionStorage.setItem("selectedRef", this.selectedRef);
    this.generateAttackIndices();
    this.resetPlot(this.lap0);
    this.numLaps = this.lap0 ? 0 : 1;
    if (this.selectedRef === "Laptime") this.generateRefTimes();
    this.updatePlot(this.rps.driverData);
  }

  generateAttackIndices(): void {
    for (const driverNum of Object.keys(this.colours.drivers)) {
      const atkSeries = this.chartRef.get(`${driverNum}-atk`);
      if (
        !(atkSeries instanceof Highcharts.Series) ||
        atkSeries.options.type !== "line"
      )
        continue;
      const atkData = atkSeries.options.data as number[];
      const atkIndices = atkData
        .map((x, i) => (x !== null ? i : null))
        .filter((i) => i !== null);
      const markerSeries = this.chartRef.get(`${driverNum}-marker`);
      if (
        !(markerSeries instanceof Highcharts.Series) ||
        markerSeries.options.type !== "line"
      )
        continue;
      const markerData = markerSeries.options.data as number[];
      const markerIndices = markerData
        .map((x, i) => (x !== null ? i : null))
        .filter((i) => i !== null);
      this.attackIndices[driverNum] = {
        attack: atkIndices,
        marker: markerIndices,
      };
    }
  }

  updateRefLapTime(): void {
    const lt = Number(this.lapTimeInput);
    if (isNaN(lt)) {
      this.lapTimeInput = String(this.refLapTime);
      return;
    }
    sessionStorage.setItem("refLapTime", String(lt));
    this.refLapTime = lt;
    this.resetPlot(this.lap0);
    this.numLaps = this.lap0 ? 0 : 1;
    this.generateRefTimes();
    this.yMin = DEFAULT_Y_MIN;
    this.biggestNegativeGap = this.yMin;
    this.biggestPositiveGap = this.yMax;
    this.updatePlot(this.rps.driverData);
    this.resetZoom();
  }

  generateRefTimes(): void {
    this.referenceTimes = [0]; // We want to start the plot at the FL so the x-axis labels line up at the FL points.
    let raceTime = 0;
    if (this.lap0) {
      const lap0Offset = this.refLapTime * this.startLinePosition;
      raceTime -= lap0Offset;
    }
    const initLap = this.lap0 ? 0 : 1;
    const loopTimings = Object.values(this.timingLoops);
    loopTimings.shift();
    loopTimings.push(1);
    const loopFractions = [loopTimings[0]];
    for (let i = 1; i < loopTimings.length; i++) {
      const loopFraction =
        Math.round((loopTimings[i] - loopTimings[i - 1]) * 1000) / 1000;
      loopFractions.push(loopFraction);
    }
    for (let lap = initLap; lap <= 100; lap++) {
      let prevLoopTime = raceTime;
      for (const loopVal of loopFractions) {
        if (loopVal === undefined) continue;
        const loopTime = prevLoopTime + this.refLapTime * loopVal;
        prevLoopTime = loopTime;
        this.referenceTimes.push(loopTime);
      }
      raceTime = prevLoopTime;
    }
  }

  updateReferenceTime(idx: number, time?: number): void {
    let leaderTime = this.leaderTimes[idx] / 1000;
    if (!leaderTime) leaderTime = time / 1000;
    const prevLeaderTime = this.leaderTimes[idx - 1] / 1000;
    const unadjustedRefTime = this.referenceTimes[idx];
    const prevRefTime = this.referenceTimes[idx - 1];
    const gap = prevRefTime - prevLeaderTime;
    const refTime = leaderTime + gap;
    this.referenceTimes[idx] = refTime;
    const delta = refTime - unadjustedRefTime;
    this.offsetRefTimes(delta, idx);
    this.updateLoopTimesReference(idx, this.referenceTimes);
  }

  offsetRefTimes(delta: number, idx: number): void {
    for (let i = idx + 1; i < this.referenceTimes.length; i++) {
      this.referenceTimes[i] += delta;
    }
  }

  updateLoopTimesReference(idx: number, referenceTimes: number[]): void {
    for (const series of this.chart.ref.series) {
      if (series.options.type !== "line") continue;
      const seriesData = series.options.data as number[];
      let driverNum = series.options.id as string;
      let isAttackSeries = false;
      let isMarkerSeries = false;
      if (driverNum.length > 2) {
        const otherTag = driverNum.split("-")[1];
        driverNum = driverNum.split("-")[0];
        if (otherTag === "atk") {
          isAttackSeries = true;
        } else {
          isMarkerSeries = true;
        }
      }
      const absoluteLoopTime = this.absoluteTimes[driverNum][idx];
      if (absoluteLoopTime === undefined) continue;
      const relativeLoopTime = referenceTimes[idx] - absoluteLoopTime / 1000;
      const relativeLoopTimeSec = Math.round(relativeLoopTime * 1000) / 1000;
      if (
        isAttackSeries &&
        (!this.attackIndices[driverNum] ||
          !this.attackIndices[driverNum].attack.includes(idx))
      ) {
        continue;
      }
      if (
        isMarkerSeries &&
        (!this.attackIndices[driverNum] ||
          !this.attackIndices[driverNum].marker.includes(idx))
      )
        continue;
      seriesData[idx] = relativeLoopTimeSec;
      if (relativeLoopTimeSec < this.biggestNegativeGap) {
        this.biggestNegativeGap = relativeLoopTimeSec;
      }
      if (relativeLoopTimeSec > this.biggestPositiveGap) {
        this.biggestPositiveGap = relativeLoopTimeSec;
      }
      // if (series.options.type === "line")
      //   series.update({ data: seriesData, type: "line" }, false);
    }
  }

  resetZoom(): void {
    this.numLaps = this.status.status.currentLap;
    this.xMax = this.numLaps * this.numLoops + MAX_X_RANGE_MARGIN;
    this.chart.ref.xAxis[0].setExtremes(this.xMin, this.xMax);
    this.chart.ref.yAxis[0].setExtremes(this.yMin, this.yMax);
    this.zoomed = false;
    this.customYAxis = false;
  }

  setYLimits(): void {
    this.customYAxis = true;
    this.yMin = Number(this.yMin);
    this.yMax = Number(this.yMax);
    this.chart.ref.yAxis[0].setExtremes(this.yMin, this.yMax);
  }

  updateColours(driversObj: {
    [key: string]: {
      num: string;
      name: string;
      team: { name: string; colour: string; fontColour: string };
    };
  }): void {
    if (!this.chartRef) return;
    const teams = new Set();
    this.firstDrivers = new Set();
    for (const [driverNum, driverObj] of Object.entries(driversObj)) {
      const firstDriver = !teams.has(driverObj.team.name);
      if (firstDriver) {
        teams.add(driverObj.team.name);
        this.firstDrivers.add(driverNum);
      }
      this.chartRef.get(driverNum)?.update(
        {
          name: driverObj.name,
          color: this.colours.getColourByDriverNum(driverNum),
          dashStyle: firstDriver ? "Solid" : "ShortDot",
        } as never,
        false
      );
      this.chartRef.get(driverNum + "-atk")?.update(
        {
          name: driverObj.name + "atk",
          color: this.colours.getColourByDriverNum(driverNum),
        } as never,
        false
      );
    }
    this.chartRef.redraw();
  }

  processFlags(flagData: { flag: string; time: number }[]): void {
    if (flagData.length === 0) return;
    const sortedFlags = this.rps.flagData.sort((a, b) => a.time - b.time);
    this.intervention =
      sortedFlags[sortedFlags.length - 1].flag !== "GREEN" &&
      sortedFlags[sortedFlags.length - 1].flag !== "NONE";
    for (let i = 0; i < sortedFlags.length; i++) {
      const flagObj = sortedFlags[i];
      if (flagObj.flag === "FULL_YELLOW" || flagObj.flag === "SAFETY_CAR") {
        const startTime = flagObj.time;
        const startIndex = this.timeToTailerIndex(startTime);
        let endIndex: number;
        if (i === sortedFlags.length - 1) {
          endIndex = this.leaderTimes.length - 1;
        } else {
          const endTime = sortedFlags[i + 1].time;
          endIndex = this.timeToLeaderIndex(endTime);
        }
        const flagColour = flagObj.flag === "FULL_YELLOW" ? "yellow" : "orange";
        const flagBandObj = {
          color: flagColour,
          from: startIndex,
          to: endIndex,
        };
        this.flagBands.push(flagBandObj);
      }
    }
    if (this.flagBands.length > 0) {
      this.chartRef.update({ xAxis: { plotBands: this.flagBands } }, true);
    }
  }

  clearFlags(): void {
    this.flagBands = [];
    this.chartRef.update({ xAxis: { plotBands: this.flagBands } }, true);
  }

  isIndexIntervention(idx: number): boolean {
    for (const flagBand of this.flagBands) {
      if (idx >= flagBand.from && idx <= flagBand.to + 1) {
        return true;
      }
    }
    return false;
  }

  extendLatestFlag(newIdx: number): void {
    this.flagBands[this.flagBands.length - 1].to = newIdx;
    this.chartRef.update({ xAxis: { plotBands: this.flagBands } }, true);
  }

  timeToLeaderIndex(time: number): number {
    for (let i = 0; i < this.leaderTimes.length; i++) {
      if (time < this.leaderTimes[i]) {
        return i;
      }
    }
    return null;
  }

  timeToTailerIndex(time: number): number {
    for (let i = 0; i < this.tailerTimes.length; i++) {
      if (time < this.tailerTimes[i]) {
        return i - 1;
      }
    }
    return Math.max(this.tailerTimes.length - 2, 0);
  }

  updateAttackDriverList(): void {
    this.attackHighlightDrivers = [];
    for (const driverObj of this.attackHighlightOptions) {
      if (driverObj.selected) {
        this.attackHighlightDrivers.push(driverObj.num);
        this.updateDriverAttackLines(driverObj.num);
      } else {
        this.removeDriverAttackLines(driverObj.num);
      }
    }
    if (!this.chartRef) return;
    this.chartRef.redraw();
  }

  updateAttackModeGap(): void {
    for (const driverNum of this.attackHighlightDrivers) {
      this.updateDriverAttackLines(driverNum);
    }
    if (!this.chartRef) return;
    this.chartRef.redraw();
  }

  static trimNullish(arr: (number | undefined)[]): number[] {
    let startIndex = 0;
    while (arr[startIndex] === undefined) {
      startIndex++;
    }
    const startTrimmed = arr.slice(startIndex) as number[];
    let endIndex = startTrimmed.length - 1;
    while (
      startTrimmed[endIndex] === undefined ||
      startTrimmed[endIndex] === null
    ) {
      endIndex--;
    }
    return startTrimmed.slice(0, endIndex + 1) as number[];
  }

  static nullifyEmpty(array: number[]): number[] {
    const result = cloneDeep(array);
    for (let i = 0; i < array.length; i++) {
      if (result[i] === undefined) result[i] = null;
    }
    return result;
  }
}
