import { Injectable } from "@angular/core";
import { urls } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import axios from "axios";

@Injectable({
  providedIn: "root",
})
export class ConfigsService {
  constructor(private auth: AuthService) {
    this.getConfigs();
  }

  chosenConfig: string;
  importGeneratorConfig: string;
  importGeneratorConfigName: string;
  availableConfigs: Array<string>;
  configMap: object;

  getConfigs() {
    axios
      .get(urls["server"] + "api/racetool/get-configs", this.auth.apiConfig)
      .then((resp) => {
        this.configMap = resp["data"];
        this.availableConfigs = Object.keys(resp["data"]);
        this.getChosenConfig();
      })
      .catch((e) => {});
  }

  getChosenConfig() {
    axios
      .get(
        urls["server"] + "api/racetool/get-current-config",
        this.auth.apiConfig
      )
      .then((resp) => {
        for (let [name, id] of Object.entries(this.configMap)) {
          if (id == resp["data"]) {
            this.chosenConfig = name;
            this.importGeneratorConfig = id;
            this.importGeneratorConfigName = name;
          }
        }
      })
      .catch((e) => {});
  }
}
