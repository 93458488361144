import { Injectable } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import axios from "axios";
import { urls } from "../../../environments/environment";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ColoursService {
  coloursMap = {};
  drivers = {};

  private colourSubject = new Subject<{
    colourUpdate: {
      [key: string]: {
        num: string;
        name: string;
        team: { name: string; colour: string; fontColour: string };
      };
    };
  }>();
  colourEvent = this.colourSubject.asObservable();

  constructor(private auth: AuthService) {
    this.getDrivers();
  }

  async getDrivers(): Promise<void> {
    await axios
      .get(urls["server"] + `api/static/drivers`, this.auth.apiConfig)
      .then(
        (resp: {
          data: {
            [key: string]: {
              num: string;
              name: string;
              team: { name: string; colour: string; fontColour: string };
            };
          };
        }) => {
          this.drivers = resp.data;
          return null;
        }
      );
    this.updateColours();
  }

  getColourByDriverNum(number: string | number): string {
    let colour: string;
    try {
      colour = this.drivers[number]["team"]["colour"];
    } catch (e) {
      colour = "pink";
    }
    return colour;
  }

  getHighlightColourByDriverNum(number: string | number): string {
    let colour: string;
    try {
      colour = this.drivers[number]["colour"];
      if (!colour) {
        colour = "black";
      }
    } catch (e) {
      colour = "rgb(0,0,0)";
    }
    return colour;
  }

  getFontColourByDriverNum(number: string | number): string {
    let fontColour: string;
    try {
      fontColour = this.drivers[number]["team"]["fontColour"];
    } catch (e) {
      fontColour = "rgb(0,0,0)";
    }
    return fontColour;
  }

  getDriverNameByNum(number: string | number): string | null {
    try {
      return this.drivers[number]["name"];
    } catch (e) {
      return null;
    }
  }

  getDriverTeamByNum(number: string): string | null {
    try {
      return this.drivers[number]["team"]["name"];
    } catch (e) {
      return null;
    }
  }

  updateColours(): void {
    this.colourSubject.next({
      colourUpdate: this.drivers,
    });
  }
}
