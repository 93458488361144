import { Component, OnInit, Input } from "@angular/core";
import { SessionStatusService } from "../../../services/session-status/session-status.service";

@Component({
  selector: "app-session-status",
  templateUrl: "./session-status.component.html",
  styleUrls: ["./session-status.component.scss"],
})
export class SessionStatusComponent implements OnInit {
  constructor(public sessionStatus: SessionStatusService) {}

  @Input() data: { currLap: number; finalLaps: number; flag: string };

  flagColours = {
    GREEN: "green",
    YELLOW: "green",
    FULL_YELLOW: "yellow",
    SAFETY_CAR: "orange",
    RED: "red",
  };

  ngOnInit(): void {
    this.sessionStatus.resetSIO();
  }
}
