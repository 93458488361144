import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
const axios = require("axios");
import { urls } from "../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: "app-admin-reset-dialogue",
  templateUrl: "./admin-reset-dialogue.component.html",
  styleUrls: ["./admin-reset-dialogue.component.scss"],
})
export class AdminResetDialogueComponent implements OnInit {
  user: any;

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AdminResetDialogueComponent>,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  trying = false;

  ngOnInit() {}

  response(clear: any) {
    if (clear) {
      this.trying = true;

      axios
        .delete(urls["server"] + "api/state/reset", this.auth.apiConfig)
        .then((resp: { status: number }) => {
          if (resp.status == 200) {
            // this.snackBar.open("Server cache cleared!", "", { duration: 3000 });
          } else {
            console.log(resp);
            this.snackBar.open("Error clearing server cache!", "OK", {
              duration: 3000,
            });
          }
          this.dialogRef.close();
        })
        .catch((err: { message: string }) => {
          console.error(err);
          this.snackBar.open("ERROR: " + err.message, "OK", { duration: 3000 });
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }
}
