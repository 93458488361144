import { Injectable } from "@angular/core";
import { FunctionsService } from "../functions/functions.service";
import { SocketioService } from "../socketio/socketio.service";
import { Subject } from "rxjs";

export type Status = {
  currentLap: number;
  currentFlag: "NONE" | "GREEN" | "FULL_YELLOW" | "SAFETY_CAR" | "RED";
  finalLaps: number;
  initFinalLaps: number;
  attackZonePosition: number;
  overtakePositions: Array<number>;
  preOvertakePositions: Array<{ pos: number; length: number }>;
  ghostSegmentPositions: Array<{ pos: number; length: number }>;
  safetyCarLapTime: number;
  safetyCarPace: number;
  interventionTime: number;
  interventionPerLap: number;
  attackScenarios: Array<string>;
  pitStop: boolean;
};

type ResetData = {
  lap0: boolean;
  finalLaps: number;
  initFinalLaps: number;
  attackZonePosition: number;
  overtakePositions: Array<number>;
  preOvertakePositions: Array<{ pos: number; length: number }>;
  ghostSegmentPositions: Array<{ pos: number; length: number }>;
  safetyCarLapTime: number;
  safetyCarPace: number;
  interventionPerLap: number;
  attackScenarios: Array<string>;
  sessionName: string;
  timingLoops: { [key: string]: number };
  pitStop: boolean;
};

@Injectable({
  providedIn: "root",
})
export class SessionStatusService {
  constructor(private sio: SocketioService, private func: FunctionsService) {
    this.reset(
      false,
      39,
      39,
      0.7,
      [0.0746, 0.814],
      [],
      [],
      145,
      75,
      220,
      ["1+3", "2+2", "3+1"],
      "Default Race",
      {
        FL: 0,
        SCL2: 0.021,
        TV1: 0.24,
        IP1: 0.388,
        TV2: 0.581,
        IP2: 0.636,
        TV3: 0.728,
        SCL1: 0.882,
      },
      this.pitStop
    );
    this.sioEventListeners();
    this.sioJoinRooms();
    this.loading = false;
  }

  private statusUpdateSubject = new Subject<Partial<Status>>();
  statusUpdateEvent = this.statusUpdateSubject.asObservable();

  private raceToolResetSubject = new Subject<{
    lap0: boolean;
    timingLoops: { [key: string]: number };
  }>();
  raceToolResetEvent = this.raceToolResetSubject.asObservable();

  private safetyCarInSubject = new Subject<{ status: string }>();
  safetyCarInEvent = this.safetyCarInSubject.asObservable();

  private interventionTimeSubject = new Subject<number>();
  interventionTimeEvent = this.interventionTimeSubject.asObservable();

  private sessionNameSubject = new Subject<{ name: string }>();
  sessionNameEvent = this.sessionNameSubject.asObservable();

  active: boolean;
  status: Status;
  interventionTime: number;
  loading: boolean;
  sessionName: string;
  lap0: boolean;
  timingLoops: { [key: string]: number } = {};
  pitStop: boolean;

  reset(
    lap0: boolean,
    finalLaps: number,
    initFinalLaps: number,
    attackZonePosition: number,
    overtakePositions: Array<number>,
    preOvertakePositions: Array<{ pos: number; length: number }>,
    ghostSegmentPositions: Array<{ pos: number; length: number }>,
    safetyCarLapTime: number,
    safetyCarPace: number,
    interventionPerLap: number,
    attackScenarios: Array<string>,
    sessionName: string,
    timingLoops: { [key: string]: number },
    pitStop: boolean
  ): void {
    this.active = true;
    this.status = {
      currentLap: lap0 ? 0 : 1,
      currentFlag: "NONE",
      finalLaps: finalLaps,
      initFinalLaps: initFinalLaps,
      attackZonePosition: attackZonePosition,
      overtakePositions: overtakePositions,
      preOvertakePositions: preOvertakePositions,
      ghostSegmentPositions: ghostSegmentPositions,
      safetyCarLapTime: safetyCarLapTime,
      safetyCarPace: safetyCarPace,
      interventionTime: 0,
      interventionPerLap: interventionPerLap,
      attackScenarios: attackScenarios,
      pitStop: pitStop,
    };
    this.sessionName = sessionName;
    this.lap0 = lap0;
    this.timingLoops = timingLoops;
    this.statusUpdateSubject.next(this.status);
    this.raceToolResetSubject.next({ lap0: lap0, timingLoops: timingLoops });
  }

  update(data: Partial<Status>): void {
    this.func.merge(this.status, data);
    this.statusUpdateSubject.next(this.status);
  }

  sioEventListeners(): void {
    this.sio.server.on("raceToolReset", (data: any) => {
      this.reset(
        data.lap0,
        data.finalLaps,
        data.initFinalLaps,
        data.attackZonePosition,
        data.overtakePositions,
        data.preOvertakePositions,
        data.ghostSegmentPositions,
        data.safetyCarLapTime,
        data.safetyCarPace,
        data.interventionPerLap,
        data.attackScenarios,
        data.sessionName,
        data.timingLoops,
        data.pitStop
      );
    });
    this.sio.server.on("raceToolUpdate", (data: Partial<Status>) => {
      this.update(data);
    });
    this.sio.server.on("scIn", (state: string) => {
      this.safetyCarInSubject.next({ status: state });
    });
    this.sio.server.on("interventionTime", (time: number) => {
      this.status.interventionTime = time;
    });
    this.sio.server.on("loading", (state: boolean) => {
      this.loading = state;
    });
    this.sio.server.on("sessionName", (name: string) => {
      this.sessionName = name;
      this.sessionNameSubject.next({ name: name });
    });
  }

  sioOffEventListeners(): void {
    this.sio.server.off("raceToolReset");
    this.sio.server.off("raceToolUpdate");
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "raceToolStatus");
  }

  sioLeaveRooms(): void {
    this.sio.leaveRoom(this.sio.server, "raceToolStatus");
  }

  resetSIO(): void {
    // doesnt work for some reason.
    this.sioOffEventListeners();
    this.sioLeaveRooms();
    this.sioEventListeners();
    this.sioJoinRooms();
  }
}
