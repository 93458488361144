<div
  [ngClass]="{
    'jaguar-theme': auth.userOptions['theme'] == 'Jaguar',
    'envision-theme': auth.userOptions['theme'] == 'Envision'
  }"
>
  <div class="container">
    <h2 *ngIf="!trying">Do you want to reset the Strategy Tool?</h2>
    <mat-spinner *ngIf="trying"></mat-spinner>
    <form class="btnContainer" *ngIf="trying == false">
      <button color="primary" mat-raised-button (click)="response(true)">
        Yes
      </button>
      <button color="primary" mat-raised-button (click)="response(false)">
        No
      </button>
    </form>
  </div>
</div>
