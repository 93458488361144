<mat-sidenav-container
  class="sidenav-container"
  [ngClass]="{
    'jaguar-theme': auth.userOptions['theme'] == 'Jaguar',
    'envision-theme': auth.userOptions['theme'] == 'Envision'
  }"
>
  <mat-sidenav #sidenav mode="over" opened="false" class="sidenav">
    <mat-toolbar class="jaguarFont">Menu</mat-toolbar>
    <mat-nav-list (click)="sidenav.close()">
      <ng-container *ngFor="let p of appPages">
        <a (click)="checkMenu(p.title)" mat-list-item [routerLink]="[p.url]"
          ><mat-icon matListIcon>{{ p.icon }}</mat-icon
          ><span class="jaguarFont" matLine>{{ p.title }}</span></a
        >
      </ng-container>
    </mat-nav-list>

    <mat-nav-list *ngIf="auth.role == 'Developer'">
      <a mat-list-item (click)="showAdminPages = !showAdminPages"
        ><mat-icon matListIcon>more</mat-icon
        ><span class="jaguarFont" matLine>ADMIN</span></a
      >
      <div *ngFor="let a of adminPages">
        <a
          class="indent"
          mat-list-item
          [routerLink]="[a.url]"
          *ngIf="showAdminPages"
          (click)="
            sidenav.close();
            showAdminPages = !showAdminPages;
            checkMenu(a.title)
          "
          ><mat-icon matListIcon>{{ a.icon }}</mat-icon
          ><span class="jaguarFont" matLine>{{ a.title }}</span></a
        >
      </div>
      <div>
        <a
          class="indent"
          mat-list-item
          *ngIf="showAdminPages"
          (click)="openClearCacheDialogue()"
          ><mat-icon matListIcon>clear</mat-icon
          ><span class="jaguarFont" matLine>Reset</span></a
        >
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <div class="fluid-container" id="contentBody">
    <ng-content>
      <!-- App content goes here -->
    </ng-content>
  </div>
  <app-alerts-popup></app-alerts-popup>
</mat-sidenav-container>
