// import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
// import { production } from "./environments/environment";
// import BuildData from "./assets/build-data.json";

// import * as Sentry from "@sentry/angular-ivy";

// if (production) {
//   Sentry.init({
//     dsn: "https://8eaf97d8c1f54b08ab647aa9bf7e1cb0@o4504327957512192.ingest.sentry.io/4505227923947520",
//     integrations: [
//       new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//       new Sentry.Replay(),
//     ], // Performance Monitoring
//     environment: production ? "production" : "local",
//     release: BuildData.version,
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
//   enableProdMode();
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
