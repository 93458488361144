<app-main-nav>
  <div
    id="main"
    class="fadeIn"
    style="
      background-image: url(./assets/images/samcar.png);
      background-position: 0% 35%;
      background-size: cover;
    "
  >
    <button
      mat-raised-button
      id="homeButton"
      (click)="home()"
      (auxclick)="homeNewTab($event)"
    >
      <mat-icon>undo</mat-icon>
    </button>
    <div id="centerBox">
      <div id="gridPanel">
        <div
          class="example-list"
          style="background-color: transparent; border: none"
        >
          <div
            class="example-box"
            style="background-color: transparent; color: white; cursor: default"
          >
            <div class="driverGrid jaguarFont" id="gridHeader">
              <div class="driverGridName">Driver</div>
              <div
                class="driverGridFF"
                matTooltip="Lap time multiplication percentage."
                matTooltipPosition="above"
              >
                Frontier Factor
              </div>
              <div
                class="driverGridTrue"
                matTooltip="Only show information that a driver would actually be able to see in real life."
                matTooltipPosition="above"
              >
                Realism Mode
              </div>
              <div
                class="driverGridAuto"
                matTooltip="Automatically send cypher codes to the strategy server."
                matTooltipPosition="above"
              >
                Auto Codes
              </div>
            </div>
          </div>
        </div>
        <div
          cdkDropList
          class="example-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="example-box jaguarFont"
            *ngFor="let driver of drivers; let i = index"
            cdkDrag
            [style.backgroundColor]="driver.team.colour"
            [style.color]="driver.team.fontColour"
          >
            <div class="driverGrid">
              <div class="driverGridPos">{{ i + 1 }}</div>
              <div class="driverGridName">
                {{ driver.name }}
              </div>
              <div class="driverGridFF">
                <div>
                  FF:
                  <input
                    matInput
                    class="ffInput"
                    [(ngModel)]="driver.frontierFactor"
                  />
                </div>
              </div>
              <div class="driverGridTrue">
                <mat-checkbox
                  [style.border]="
                    driver.trueDriver
                      ? 'none'
                      : '2px solid ' + driver.team.fontColour
                  "
                  [style.transform]="driver.trueDriver ? 'none' : 'scale(0.75)'"
                  style="border-radius: 3px"
                  [(ngModel)]="driver.trueDriver"
                >
                </mat-checkbox>
              </div>
              <div
                class="driverGridAuto"
                [style.visibility]="
                  driver.team.name == 'Jaguar' || driver.team.name == 'Envision'
                    ? 'visible'
                    : 'hidden'
                "
              >
                <mat-checkbox
                  [style.border]="
                    driver.autoCodes
                      ? 'none'
                      : '2px solid ' + driver.team.fontColour
                  "
                  [style.transform]="driver.autoCodes ? 'none' : 'scale(0.75)'"
                  style="border-radius: 3px"
                  [(ngModel)]="driver.autoCodes"
                ></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <button
          mat-raised-button
          id="randomGridButton"
          class="jaguarFont"
          (click)="randomizeGrid()"
        >
          Random
        </button>
      </div>
      <div id="paramsPanel" class="jaguarFont">
        Parameters
        <div id="paramsListWrapper" [style.height]="showMap ? '25vh' : '75vh'">
          <div
            class="paramList"
            *ngFor="let param of importParams; let i = index"
          >
            <div class="paramRow">
              <div class="paramName">
                <div
                  [matTooltip]="param.description"
                  matTooltipPosition="right"
                >
                  {{ param.name }}
                </div>
              </div>
              <div class="paramValue">
                <ng-template [ngIf]="param.type == 'boolean'">
                  <div class="paramCheckboxField">
                    <mat-checkbox [(ngModel)]="param.value"> </mat-checkbox>
                  </div>
                </ng-template>
                <ng-template [ngIf]="param.type !== 'boolean'">
                  <div class="paramValueFormField">
                    <input matInput type="text" [(ngModel)]="param.value" />
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- <button
          mat-raised-button
          (click)="toggleMap()"
          class="jaguarFont"
          id="showMapButton"
        >
          {{ showMap ? "Hide Map" : "Show Map " }}
        </button>
        <div
          id="mapContainer"
          [style.visibility]="showMap ? 'visible' : 'hidden'"
          [style.height]="showMap ? '' : '0'"
          [style.border-color]="map.firstPointClicked ? 'gold' : 'white'"
        >
          <app-map #map></app-map>
        </div>
        <div
          id="mapText"
          [style.visibility]="showMap ? 'visible' : 'hidden'"
          [style.height]="showMap ? '' : '0'"
        >
          Click to define start and end positions for ghost zones.<br />
          Double click to clear.
        </div> -->
      </div>

      <div id="outputPanel">
        <div class="jaguarFont">Load Existing Import Parameters</div>
        <div id="loadFieldWrapper">
          <mat-form-field id="loadField" appearance="none">
            <mat-select
              [(value)]="configs.importGeneratorConfigName"
              id="loadSelect"
              style="width: 20rem !important"
            >
              <mat-option
                *ngFor="let cfg of configs.availableConfigs"
                [value]="cfg"
              >
                {{ cfg }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            mat-raised-button
            class="jaguarFont"
            style="font-size: 0.8rem; text-align: center; width: 90px"
            (click)="grid.loadImport(configs.importGeneratorConfigName)"
          >
            Load
          </button>
        </div>
        <div class="jaguarFont">New Import Details</div>
        <mat-form-field
          appearance="none"
          class="outputFormField"
          id="importNameFormField"
        >
          <mat-label id="importNameLabel" style="color: gray"
            >e.g. S09R06SAP_V10</mat-label
          >
          <mat-hint align="end" id="importNameHint" style="color: black"
            >Import Name</mat-hint
          >
          <input
            matInput
            type="text"
            id="importNameInput"
            [(ngModel)]="importName"
          />
        </mat-form-field>

        <mat-form-field
          appearance="none"
          class="outputFormField"
          id="strategyIdFormField"
        >
          <mat-label id="strategyIdLabel" style="color: gray"
            >24 byte Hexadecimal ID</mat-label
          >
          <mat-hint align="end" id="strategyIdHint" style="color: black"
            >Strategy Import</mat-hint
          >

          <input
            matInput
            type="text"
            id="strategyIdInput"
            [(ngModel)]="strategyId"
          />
        </mat-form-field>

        <!-- <button
          mat-raised-button
          id="generateButton"
          class="jaguarFont"
          (click)="map.generateZones(); generate(map.generatedZones)"
        > -->
        <button
          mat-raised-button
          id="generateButton"
          class="jaguarFont"
          (click)="generate()"
        >
          Generate
        </button>
        <div id="gridOutput" class="jaguarFont">
          <div class="gridArrayLabel">
            <div class="gridArrayTitle">Grid Array</div>
            <div class="copyGrid">
              <button
                mat-raised-button
                class="copyGridButton"
                (click)="copyGrid()"
                [style.visibility]="gridString ? 'visible' : 'hidden'"
              >
                <mat-icon>file_copy</mat-icon>
              </button>
            </div>
          </div>
          <div class="gridJSON">
            <pre>{{ gridString }}</pre>
          </div>
        </div>
        <div id="gridMessage" class="jaguarFont">
          <div class="gridArrayLabel">
            <div class="gridArrayTitle">Grid Message (RTAP)</div>
            <div class="copyGrid">
              <button
                mat-raised-button
                class="copyGridButton"
                (click)="copyGridMessage()"
                [style.visibility]="gridMessage ? 'visible' : 'hidden'"
              >
                <mat-icon>file_copy</mat-icon>
              </button>
            </div>
          </div>
          <div class="gridJSON">
            <pre>{{ gridMessage }}</pre>
          </div>
        </div>
        <div id="importOutput" class="jaguarFont">
          <div class="gridArrayLabel">
            <div class="gridArrayTitle">Import</div>
            <div class="copyGrid">
              <button
                mat-raised-button
                class="copyGridButton"
                (click)="copyImportOutput()"
                [style.visibility]="gridMessage ? 'visible' : 'hidden'"
              >
                <mat-icon>file_copy</mat-icon>
              </button>
            </div>
          </div>
          <div class="gridJSON">
            <pre>{{ importOutputString }}</pre>
          </div>
        </div>
        <button
          mat-raised-button
          id="publishButton"
          class="jaguarFont"
          (click)="publishImport()"
          [disabled]="!generated || strategyId.length !== 24"
        >
          Publish Import
        </button>
      </div>
    </div>
  </div>
</app-main-nav>
