<app-main-nav>
  <!-- <app-theme></app-theme> -->
  <div
    id="main"
    class="fadeIn"
    style="
      background-image: url(./assets/images/S9grid.png);
      background-position: 0% 35%;
    "
  >
    <div id="topBar">
      <div id="otherMenuButtons">
        <button
          mat-raised-button
          class="topBarButton jaguarFont"
          (click)="raceManager()"
          (auxclick)="raceManagerNewTab($event)"
          *ngIf="RACE_MANAGER_ROLES.includes(auth.role)"
        >
          <div class="topBarInButton">
            <mat-icon>outlined_flag</mat-icon> &nbsp;&nbsp;Race Manager
          </div>
        </button>
        <br />
        <button
          mat-raised-button
          class="topBarButton jaguarFont"
          (click)="viewer()"
          (auxclick)="viewerNewTab($event)"
        >
          <div class="topBarInButton">
            <mat-icon>tv</mat-icon>&nbsp;&nbsp;Viewer
          </div>
        </button>
      </div>

      <app-logout-button class="topBarButton jaguarFont"></app-logout-button>
    </div>
    <div
      id="welcome"
      *ngIf="auth.user && !VIEWER_ONLY_ROLES.includes(auth.role)"
      class="jaguarFont"
    >
      <div class="subtitle">
        <h3>Choose your driver:</h3>
      </div>
    </div>
    <div
      id="viewerWelcome"
      class="subtitle jaguarFont"
      *ngIf="VIEWER_ONLY_ROLES.includes(auth.role)"
    >
      <h1>Welcome! Your role is viewer!</h1>
    </div>
    <div id="driverLoading" *ngIf="!doneDisabling || status.loading">
      <mat-spinner [diameter]="400"></mat-spinner>
    </div>
    <div
      id="driverSelect"
      *ngIf="
        doneDisabling &&
        !VIEWER_ONLY_ROLES.includes(auth.role) &&
        !status.loading
      "
      class="fadeIn"
    >
      <mat-grid-list cols="6" rowHeight="122%" id="driverGridList">
        <mat-grid-tile
          *ngFor="let tile of driverTiles"
          [colSpan]="tile.cols"
          [rowspan]="tile.rows"
          [style.background]="transparent"
          class="driverTile"
        >
          <button
            mat-raised-button
            class="driverButton jaguarFont"
            (click)="selectDriver(tile)"
            (auxclick)="selectDriverNewTab(tile, $event)"
            *ngIf="driverEnabled[tile.num]"
            [style.border-color]="tile.color"
          ></button>
          <div
            class="driverButtonPic"
            [ngStyle]="{
              'background-image': driverEnabled[tile.num]
                ? 'url(../../../assets/images/headshots/' + tile.num + '.png)'
                : ''
            }"
          ></div>
          <div class="driverButtonNum jaguarFont">{{ tile.text }}</div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</app-main-nav>
