import { Injectable } from "@angular/core";
import { SocketioService } from "../socketio/socketio.service";
import { FunctionsService } from "../functions/functions.service";
import { SessionStatusService } from "../session-status/session-status.service";
import { Subject } from "rxjs";

type RacePlotData = {
  [key: string]: {
    laps: {
      [key: string]: {
        loops: { [key: string]: { time: number; attackMode: boolean } };
      };
    };
  };
};
@Injectable({
  providedIn: "root",
})
export class RacePlotService {
  constructor(
    private sio: SocketioService,
    private func: FunctionsService,
    private status: SessionStatusService
  ) {
    this.sioEventListeners();
    this.sioJoinRooms();
    this.subEvents();
  }

  private racePlotUpdateSubject = new Subject<RacePlotData>();
  racePlotUpdateEvent = this.racePlotUpdateSubject.asObservable();

  private racePlotFlagSubject = new Subject<{ flag: string; time: number }[]>();
  racePlotFlagEvent = this.racePlotFlagSubject.asObservable();

  private racePlotConfigSubject = new Subject<{
    lap0: boolean;
    startLinePosition: number;
    attackModeGap: number;
  }>();
  racePlotConfigEvent = this.racePlotConfigSubject.asObservable();

  driverData: RacePlotData = {};
  flagData: { flag: string; time: number }[] = [];
  events = {};
  config: { lap0: boolean; startLinePosition: number; attackModeGap: number } =
    {
      lap0: false,
      startLinePosition: 0,
      attackModeGap: 1.5,
    };

  sioEventListeners(): void {
    this.sio.server.on("raceplotData", (raceplotData: RacePlotData) => {
      this.func.merge(this.driverData, raceplotData);
      this.racePlotUpdateSubject.next(raceplotData);
    });
    this.sio.server.on(
      "raceplotFlagData",
      (raceplotFlagData: { flag: string; time: number }[]) => {
        this.flagData = this.flagData.concat(raceplotFlagData);
        this.racePlotFlagSubject.next(raceplotFlagData);
      }
    );
    this.sio.server.on(
      "raceplotConfigData",
      (configData: {
        lap0: boolean;
        startLinePosition: number;
        attackModeGap: number;
      }) => {
        this.config = configData;
        this.racePlotConfigSubject.next(configData);
      }
    );
  }

  sioJoinRooms(): void {
    this.sio.joinRoom(this.sio.server, "raceplot");
  }

  subEvents(): void {
    this.events["raceToolReset"] = this.status.raceToolResetEvent.subscribe(
      () => {
        this.reset();
      }
    );
  }

  unsubEvents(): void {
    if (this.events["raceToolReset"] != undefined)
      this.events["raceToolReset"].unsubscribe();
  }

  reset(): void {
    this.driverData = {};
    this.flagData = [];
  }
}
